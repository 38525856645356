import {
  AuthorizationListener,
  AuthorizationNotifier,
  AuthorizationRequestResponse,
  BasicQueryStringUtils,
  DefaultCrypto,
  LocalStorageBackend,
  LocationLike,
  RedirectRequestHandler,
  UnderlyingStorage
} from "@openid/appauth";
import { StoragePartition } from "@remhealth/ui";
import { LoginRequestHandler } from "./loginManager";

export class BrowserLoginHandler extends RedirectRequestHandler implements LoginRequestHandler {
  constructor() {
    super(
      new LocalStorageBackend(new BrowserStorage()),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    );
  }

  public setAuthorizationListener(listener: AuthorizationListener): void {
    const notifier = new AuthorizationNotifier();
    notifier.setAuthorizationListener(listener);
    super.setAuthorizationNotifier(notifier);
  }

  public completeAuthorizationRequest(): Promise<AuthorizationRequestResponse | null> {
    return super.completeAuthorizationRequest();
  }
}

class NoHashQueryStringUtils extends BasicQueryStringUtils {
  public parse(input: LocationLike) {
    return super.parse(input, false);
  }
}

const requestIdKey = "appauth_current_authorization_request";
const authStorage = new StoragePartition(window.localStorage, "appauth");

class BrowserStorage implements UnderlyingStorage {
  public get length(): number {
    return authStorage.length + (window.sessionStorage.getItem(requestIdKey) ? 1 : 0);
  }

  public clear(): void {
    window.sessionStorage.removeItem(requestIdKey);
    authStorage.clear();
  }

  public getItem(key: string): string | null {
    // Special requirement to avoid multiple tabs fighting each other over concurrent logins
    if (key === requestIdKey) {
      return window.sessionStorage.getItem(key);
    }

    return authStorage.getItem(key);
  }

  public removeItem(key: string): void {
    // Special requirement to avoid multiple tabs fighting each other over concurrent logins
    if (key === requestIdKey) {
      window.sessionStorage.removeItem(key);
      return;
    }

    authStorage.removeItem(key);
  }

  public setItem(key: string, data: string): void {
    // Special requirement to avoid multiple tabs fighting each other over concurrent logins
    if (key === requestIdKey) {
      window.sessionStorage.setItem(key, data);
      return;
    }

    authStorage.setItem(key, data, { slidingExpiration: "90d" });
  }
}
