import { type Complete, FormContent } from "@remhealth/core";
import {
  AdditionalService,
  ApproximateRange,
  CarePlanActivity,
  Coding,
  Diagnosis,
  Duration,
  EpisodeOfCare,
  EvidenceBasedPracticeCoding,
  Flavor,
  GroupNoteDefinition,
  HealthcareService,
  ItineraryTopic,
  Location,
  LocationRole,
  Media,
  NoteDefinition,
  NoteSectionForm,
  Organization,
  Patient,
  PatientNote,
  Practitioner,
  ProblemActivity,
  Program,
  ProgramEnrollment,
  ProgressNoteCustomOption,
  QuestionnaireAnswer,
  Reference,
  RichText,
  SessionTimeFormat,
  Signature,
  SignatureConfiguration,
  VisitStatus
} from "@remhealth/apollo";
import { OpenNoteDialogOptions } from "~/contexts";

export type EnrollmentKey = Flavor<string, "enrollment">;

export interface Enrollment {
  id: EnrollmentKey;
  episodeOfCare: Reference<EpisodeOfCare>;
  programEnrollment?: ProgramEnrollment;
}

export interface Session {
  start: Date | undefined;
  end: Date | undefined;
}

export interface TextNoteSection {
  name: string;
  text: RichText | undefined;
  required: boolean;
  includeInPatientView: boolean;
  code: string | undefined;
}

export interface FormNoteSection {
  noteSectionForm: NoteSectionForm;
  questionnaireAnswers: QuestionnaireAnswer[];
  required: boolean;
  name: string;
}

export interface SessionTimeSectionValues {
  period: Complete<ApproximateRange>;
  sessionTimeFormat: SessionTimeFormat | undefined;
  duration: Duration | undefined;
  invalidDuration: boolean;
  sessions: Session[];
  units: number | undefined;
  serviceType: HealthcareService | undefined;
}

export interface ProblemListSectionValues {
  period: Complete<ApproximateRange>;
  problems: ProblemActivity[];
}

export interface DiagnosisSectionValues {
  period: Complete<ApproximateRange>;
  diagnoses: Diagnosis[];
}

export interface GoalSectionValues {
  definition: NoteDefinition;
  period: Complete<ApproximateRange>;
  carePlans: CarePlanActivity[];
}

export interface GoalTrackingValues {
  carePlanActivities: CarePlanActivity[];
}

export interface TextSectionValues {
  textSections: TextNoteSection[];
  definition: Reference<NoteDefinition> | Reference<GroupNoteDefinition>;
  serviceType: HealthcareService | undefined;
}

export interface CtoneCustomDropdownValues {
  ctoneCustomDropdown: ProgressNoteCustomOption | undefined;
}

export interface ClinicalQualityIndicatorValues {
  clinicalQualityCodes: ProgressNoteCustomOption[];
}

export interface EvidenceBasedPracticesValues {
  evidenceBasedPracticesCodings: EvidenceBasedPracticeCoding[];
}

export interface AdditionalServiceValues {
  additionalServices: Complete<AdditionalService>[];
}

export interface NoteFormIdentity {
  id: string;
}

export type SignatureType = "patient" | "guardian" | "other" | "adhoc" | "practitioner";

type FormSignatureValues = {
  type: SignatureType;
  required: boolean;
  customLabel?: string;
  linkId?: string;
};

export type DraftSignature = Pick<Signature, "signer" | "role"> & FormSignatureValues;

export type PatientSignature = Omit<Signature, "signatureImage"> & FormSignatureValues & {
  signatureImage: Reference<Media>;
};

export type ValidationMode = "loose" | "patient-viewables" | "strict";

export interface NoteForm extends
  NoteFormIdentity,
  SessionTimeSectionValues,
  DiagnosisSectionValues,
  ProblemListSectionValues,
  GoalSectionValues,
  GoalTrackingValues,
  TextSectionValues,
  CtoneCustomDropdownValues,
  ClinicalQualityIndicatorValues,
  EvidenceBasedPracticesValues,
  AdditionalServiceValues {
  patient: Reference<Patient>;
  definition: NoteDefinition;
  insurances: Reference<Organization>[];
  appointmentId: string | undefined;
  episodeOfCareId: string | undefined;
  program: Reference<Program> | undefined;
  location: Location | undefined;
  locationRole: LocationRole | undefined;
  locationKind: Location | undefined;
  owner: Reference<Practitioner> | undefined;
  serviceType: HealthcareService | undefined;
  formSections: FormNoteSection[];
  overrideSessionInformation: boolean | undefined;
  topics: ItineraryTopic[];
  programEnrollment: ProgramEnrollment | undefined;
  category: Coding | undefined;
  status: VisitStatus;
  signatureConfiguration: SignatureConfiguration | undefined;
  groupNoteInvalid: boolean;
  patientSignatures: (DraftSignature | PatientSignature)[];
  hideElementLinkIds: Set<string>;
}

export interface GroupNoteForm extends NoteFormIdentity, SessionTimeSectionValues, TextSectionValues {
  definition: GroupNoteDefinition;
  appointmentId: string | undefined;
  program: Program | undefined;
  location: Location;
  locationRole: LocationRole | undefined;
  locationKind: Location | undefined;
  owner: Reference<Practitioner> | undefined;
  textSections: TextNoteSection[];
  formSections: FormNoteSection[];
  topics: ItineraryTopic[];
  units: number | undefined;
}

export interface MultiServiceNoteForm extends NoteFormIdentity {
  program: Program | undefined;
  location: Location;
  locationRole: LocationRole | undefined;
  locationKind: Location | undefined;
  owner: Reference<Practitioner> | undefined;
  period: Complete<ApproximateRange>;
}

export interface TemplateForm {
  name: string;
  description: string | undefined;
  visitStatuses: VisitStatus[];
  services: Reference<HealthcareService>[];
  textSections: TextNoteSection[];
  inheritServices: boolean;
  inheritVisitStatuses: boolean;
  formSections: FormNoteSection[];
}

export interface MulitNoteCounts {
  signedCount: number;
  totalCount: number;
}

export type GroupNoteTemplateType = Extract<keyof GroupNoteDefinition, "showTemplate" | "noShowTemplate" | "cancelledTemplate">;

export interface NoteSwitchState {
  subject: Reference<Patient>;
  note: PatientNote | null;
  options: OpenNoteDialogOptions | undefined;
  message: string | JSX.Element;
  confirmText: string;
  cancelText: string;
}

export function isGroupNoteForm(form: FormContent<NoteForm> | FormContent<GroupNoteForm> | FormContent<MultiServiceNoteForm>): form is FormContent<GroupNoteForm> {
  return "definition" in form.fields && form.fields.definition?.value?.resourceType === "GroupNoteDefinition";
}

export function isMultiServiceNoteForm(form: FormContent<NoteForm> | FormContent<MultiNoteForm>): form is FormContent<MultiServiceNoteForm> {
  return !("definition" in form.fields);
}

export type MultiNoteForm = GroupNoteForm | MultiServiceNoteForm;

export function isMultiNoteForm(form: FormContent<NoteForm> | FormContent<MultiNoteForm>) {
  return isGroupNoteForm(form) || isMultiServiceNoteForm(form);
}

export function createEnrollmentKey(episodeOfCareId: string | undefined, programId: string | undefined): EnrollmentKey {
  return `${episodeOfCareId}-${programId}` as EnrollmentKey;
}
