import React, { useState } from "react";
import classnames from "classnames";
import { Classes } from "@blueprintjs/core";
import { Filter, FilterKeep, FilterRemove } from "@remhealth/icons";
import { useAutomation } from "../hooks";
import { Button, IconButton } from "./button";
import { Checkbox } from "./toggles";
import { Placement } from "./popover";
import { getComponentId } from "./formScope";
import { ButtonRow, Container, Content, FilterPopover, ItemWrapper } from "./columnFilter.styles";

export interface ColumnFilterProps<T> {
  "aria-label": string;
  items: ReadonlyArray<T>;
  selectedItems: ReadonlyArray<T>;
  labelRenderer: (item: T) => JSX.Element | string;
  placement?: Placement;
  className?: string;
  onChange: (selectedItems: T[]) => void;
}

export function ColumnFilter<T>(props: ColumnFilterProps<T>) {
  const {
    selectedItems: controlledSelectedItems,
    items,
    labelRenderer,
    onChange,
    placement = "bottom-start",
    className,
  } = props;

  const [pendingSelections, setPendingSelections] = useState<T[]>();

  const { label, id } = useAutomation(props, "column-filter");
  const menuId = getComponentId(id, "listbox");

  const selectedItems = pendingSelections ?? controlledSelectedItems;
  const hasFiltered = selectedItems.length > 0;
  const hasChanges = selectedItems.length !== controlledSelectedItems.length
    || selectedItems.some(i => !controlledSelectedItems.includes(i))
    || controlledSelectedItems.some(i => !selectedItems.includes(i));

  const content = (
    <Container data-filter-menu id={menuId}>
      <Content>
        {items.map((item, index) => (
          <ItemWrapper key={index}>
            <Checkbox
              alignIndicator="right"
              checked={selectedItems.includes(item)}
              data-item={labelRenderer(item)}
              labelElement={labelRenderer(item)}
              onChange={e => handleFilterChange(e, item)}
            />
          </ItemWrapper>
        ))}
      </Content>
      {(hasFiltered || hasChanges) && (
        <ButtonRow>
          {hasFiltered && (
            <Button
              data-filter-clear
              minimal
              className={classnames(Classes.POPOVER_DISMISS, "clear")}
              icon={<FilterRemove />}
              intent="primary"
              label="Clear"
              onClick={handleClear}
            />
          )}
          {hasChanges && (
            <Button
              data-filter-button
              minimal
              className={classnames(Classes.POPOVER_DISMISS, "filter")}
              icon={<FilterKeep />}
              intent="primary"
              label="Apply"
            />
          )}
        </ButtonRow>
      )}
    </Container>
  );

  return (
    <FilterPopover className={className} content={content} placement={placement} onClosed={handleClosed} onOpened={handleOpened}>
      <IconButton
        data-column-filter
        minimal
        tooltip
        aria-label={label ?? props["aria-label"]}
        icon={hasFiltered ? <FilterKeep /> : <Filter />}
        intent={hasFiltered ? "primary" : "none"}
      />
    </FilterPopover>
  );

  function handleFilterChange(event: React.FormEvent<HTMLInputElement>, item: T): void {
    const { checked } = event.currentTarget;
    setPendingSelections(pendingSelections => {
      const selectedItems = pendingSelections ?? controlledSelectedItems;
      return checked ? [...selectedItems, item] : selectedItems.filter(r => r !== item);
    });
  }

  function handleClear() {
    setPendingSelections([]);
  }

  function handleOpened() {
    setPendingSelections([...controlledSelectedItems]);
  }

  function handleClosed() {
    if (hasChanges && pendingSelections) {
      onChange(pendingSelections);
    }
    setPendingSelections(undefined);
  }
}
