import React, { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { Classes, InputGroupProps as CoreInputGroupProps, TagInputProps as CoreTagInputProps } from "@blueprintjs/core";
import * as Core from "@blueprintjs/select";
import { FormField, getIntent } from "../utils/form";
import { DefaultNoResults, ItemListRendererProps, ListGroupRenderer, extendItemListRendererProps } from "../utils";
import { useAutomation, useCallbackRef, useControllableState } from "../hooks";
import { IconButton } from "./button";
import { usePopperModifiers } from "./popover";
import { TagInputProps, toNativeProps as tagInputToNativeProps } from "./tagInput";
import { FormScope, getComponentId } from "./formScope";
import { ListItemsProps, SelectPopoverProps, useListItems } from "./useListItems";
import { Menu, MenuProps } from "./menu";
import { useSelectMenuProps } from "./useSelectMenuProps";
import { type TagProps } from "./tag";

export interface MultiSelectListRendererProps<T> extends ItemListRendererProps<T> {
  selectedItems: T[];
}

export type MultiSelectListRenderer<T> = (itemListProps: MultiSelectListRendererProps<T>) => JSX.Element | null;

type PropOmissions =
  | "items"
  | "activeItem"
  | "selectedItems"
  | "itemRenderer"
  | "itemListRenderer"
  | "menuProps"
  | "popoverProps"
  | "resetOnQuery"
  | "resetOnSelect"
  | "tagInputProps"
  | "tagRenderer"
  | "onActiveItemChange"
  | "onItemSelect";

type LiftedTagInputProps = Pick<TagInputProps,
  | "disabled"
  | "intent"
  | "leftIcon"
  | "rightElement"
  | "placeholder"
  | "large"
>;

export interface MultiSelectProps<T> extends Omit<Core.MultiSelectProps<T>, PropOmissions | keyof ListItemsProps<T>>, ListItemsProps<T>, LiftedTagInputProps {
  items: ReadonlyArray<T>;

  /**
   * Gives the input a minimal style.
   * @default false
   */
  minimal?: boolean;
  /**
   * If true, defaults the `inputProps.rightElement` to a clear button that will clear the selection.
   * Ignored if `inputProps.rightElement` is provided.
   * @default false
   */
  clearable?: boolean;

  /**
   * Whether the component is non-interactive.
   * @default false
   */
  disabled?: boolean;

  /**
   * Whether the component is readonly.
   * @default false
   */
  readOnly?: boolean;

  /**
   * Whether the active item should be reset to the first matching item _every
   * time the query changes_ (via prop or by user input).
   *
   * @default false
   */
  resetOnQuery?: boolean;

  /**
   * Whether the tag input filter should be reset when the user blurs from the field.
   * @default true
   */
  resetOnBlur?: boolean;

  /**
   * Does not render tags.
   * @default false
   */
  tagless?: boolean;

  menuProps?: MenuProps;

  popoverProps?: SelectPopoverProps;

  tagRenderer?: (item: T) => React.ReactNode;

  groupRenderer?: ListGroupRenderer<T, MultiSelectListRendererProps<T>>;

  itemListRenderer?: MultiSelectListRenderer<T>;

  tagInputProps?: Partial<MultiSelectTagInputProps<T>> & object;

  /**
   * Controlled selected items.
   */
  selectedItems?: T[];

  /**
   * Items to be already selected when the component is initialized.
   */
  defaultSelectedItems?: T[];

  field?: FormField<T[] | undefined>;

  /**
   * Invoked when an item is selected.  Note that this does not get called if the selection is removed.  Use `onItemRemove` removal notifications.
   */
  onItemSelect?: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void;

  /**
   * Invoked when an item is added.
   */
  onItemAdd?: (item: T) => void;

  /**
   * Invoked when an item is removed.
   */
  onItemRemove?: (item: T) => void;

  /**
   * Invoked when the item selections change.
   */
  onSelectedItemsChange?: (selectedItems: T[]) => void;
}

export interface MultiSelectTagInputProps<T> extends Omit<TagInputProps, "disabled" | "rightElement" | "tagProps"> {
  id?: string;
  tagProps?: TagProps | ((item: T, index: number) => TagProps);
}

const modifiersNoFlipVariation = {
  flip: { options: { flipVariations: false } },
};

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const { itemPredicate, itemRenderer, textRenderer } = useListItems({
    ...props,
    listOptionPropFactory,
  }, [props.groupRenderer]);

  const {
    className,
    defaultSelectedItems = [],
    tagInputProps = {},
    popoverProps = {},
    clearable,
    items,
    field,
    large,
    tagless,
    menuProps: controlledMenuProps,
    popoverTargetProps,
    minimal = false,
    resetOnBlur = true,
    resetOnQuery = false,
    initialContent,
    noResults = DefaultNoResults,
    readOnly: controlledReadOnly,
    disabled: controlledDisabled,
    placeholder = "Search...",
    itemsEqual,
    rightElement: controlledRightElement,
    selectedItems: controlledSelectedItems,
    optionRenderer,
    infoRenderer,
    groupRenderer,
    tagRenderer = textRenderer,
    itemListRenderer = defaultItemListRenderer,
    onItemSelect,
    onQueryChange,
    onItemAdd,
    onItemRemove,
    onSelectedItemsChange,
    ...restProps
  } = props;

  const [query, setQuery] = useControllableState<string>(props.query, onQueryChange, "");
  const [activeItem, setActiveItem] = useState<T | Core.CreateNewItem | null>(null);
  const [uncontrolledSelectedItems, setUncontrolledSelectedItems] = useState<T[]>(defaultSelectedItems);

  const tagRendererCallback = useCallbackRef(renderTags);
  const tagPropsCallback = useCallbackRef(handleTagProps);

  const multiselectRef = useRef<Core.MultiSelect<T>>(null);

  const selectedItems = field
    ? field.value ?? []
    : controlledSelectedItems ?? uncontrolledSelectedItems;

  const { width, maxWidth, maxHeight, matchTargetWidth, positioningStrategy = "fixed", ...restPopoverProps } = popoverProps;

  const { label, id, controlId, errorId } = useAutomation({ ...props, id: props.tagInputProps?.id, name: props.tagInputProps?.inputProps?.name });
  const inputId = getComponentId(id, "input");
  const popoverId = getComponentId(id, "popover");

  const controlledModifiers = useMemo(() => ({
    ...restPopoverProps.modifiers,
    ...modifiersNoFlipVariation,
  }), [restPopoverProps.modifiers]);

  const [modifiers, modifiersCustom, popperPopoverTargetProps] = usePopperModifiers({
    label,
    popoverId,
    positioningStrategy,
    flowTo: inputId,
    modifiers: controlledModifiers,
    modifiersCustom: restPopoverProps.modifiersCustom,
  });

  const sortedItems = useMemo(() => groupRenderer?.([...items]).flatMap(i => i.items) ?? [...items], [items, groupRenderer]);

  const menuProps = useSelectMenuProps({
    label: `${label ?? "MultiSelect List"} Items`,
    id,
    maxWidth,
    maxHeight,
    width,
    matchTargetWidth,
    defaultMaxWidth: 600,
  });

  useEffect(() => {
    if (props.selectedItems) {
      field?.onChange(props.selectedItems);
      setUncontrolledSelectedItems(props.selectedItems);
    }
  }, [props.selectedItems]);

  let readOnly = controlledReadOnly;
  let disabled = controlledDisabled;

  if (field?.disabled) {
    disabled = true;
  }

  if (field?.readOnly) {
    readOnly = true;
  }

  const {
    tagProps = {},
    inputProps = {},
    intent = getIntent(field),
    ...restTagInputProps
  } = tagInputProps;

  let tagMinimal = true;
  let tagFill = true;
  let popoverMinimal = true;

  // Default tagProps.minimal to true if undefined
  if (typeof tagProps === "object" && typeof tagProps.minimal === "boolean") {
    tagMinimal = tagProps.minimal;
  }

  // Default tagProps.fill to true if undefined
  if (typeof tagProps === "object" && typeof tagProps.fill === "boolean") {
    tagFill = tagProps.fill;
  }

  // Default popoverProps.minimal to true if undefined
  if (typeof restPopoverProps.minimal === "boolean") {
    popoverMinimal = restPopoverProps.minimal;
  }

  let rightElement = controlledRightElement;
  if (rightElement === undefined) {
    if (clearable && selectedItems.length > 0) {
      rightElement = (
        <IconButton
          minimal
          square
          aria-label={label ? `Clear ${label}` : "Clear MultiSelect"}
          disabled={disabled}
          icon="cross"
          id={getComponentId(id, "clear")}
          onClick={handleClear}
        />
      );
    } else if (items.length > 0) {
      rightElement = (
        <IconButton
          minimal
          square
          aria-label={label ? `Expand ${label}` : "Expand MultiSelect"}
          disabled={disabled}
          icon="caret-down"
          id={getComponentId(id, "expand")}
          tabIndex={-1}
        />
      );
    }
  }

  if (rightElement === null) {
    rightElement = undefined;
  }

  const customizedTagInputProps: Partial<CoreTagInputProps> = {
    ...tagInputToNativeProps({
      ...restTagInputProps,
      large,
      rightElement,
      tagProps: tagPropsCallback,
      onRemove: handleItemRemove,
    }),
    className: classnames(restTagInputProps?.className, {
      "none-selected": tagless || selectedItems.length === 0,
      "vertical": tagFill,
    }),
    intent,
    disabled,
    inputProps: {
      ...popperPopoverTargetProps,
      ...inputProps,
      placeholder,
      "id": inputId,
      "aria-invalid": field?.error ? true : undefined,
      "aria-errormessage": errorId,
      "data-errormessage": field?.error ? field.errorText : undefined,
      "autoComplete": inputProps.autoComplete ?? "off",
      "name": inputProps.name ?? field?.name,
      "role": "combobox",
      "disabled": disabled || inputProps.disabled,
      "readOnly": readOnly || inputProps.readOnly,
    } as CoreInputGroupProps,
  };

  // Fix issue with undefined acting like controlled value
  if (restPopoverProps && restPopoverProps.isOpen === undefined) {
    delete restPopoverProps.isOpen;
  }

  const customizedPopoverProps: Core.MultiSelectProps<T>["popoverProps"] = {
    targetTagName: "div",
    position: !tagless && tagFill ? "right-top" : "bottom-left",
    ...restPopoverProps,
    positioningStrategy,
    matchTargetWidth,
    modifiers,
    modifiersCustom,
    onOpening: handlePopoverOpening,
    onClosing: handlePopoverClosing,
    minimal: popoverMinimal,
    className: classnames({ [Classes.MINIMAL]: minimal }, popoverProps.className),
  };

  if (disabled === true || field?.readOnly) {
    customizedPopoverProps.isOpen = false;
  }

  const createNewItemFromQuery = query
    && props.createNewItemFromQuery
    && props.createNewItemRenderer?.(query, false, () => { }) !== undefined
    ? props.createNewItemFromQuery
    : undefined;

  return (
    <FormScope controlId={controlId}>
      <Core.MultiSelect<T>
        {...restProps}
        ref={multiselectRef}
        scrollToActiveItem
        activeItem={activeItem ?? sortedItems[0]}
        className={classnames("multiselect", className)}
        createNewItemFromQuery={createNewItemFromQuery}
        initialContent={initialContent}
        itemListRenderer={renderList}
        itemPredicate={itemPredicate}
        itemRenderer={itemRenderer}
        items={sortedItems}
        itemsEqual={itemsEqual}
        menuProps={menuProps}
        noResults={noResults}
        placeholder={placeholder}
        popoverProps={customizedPopoverProps}
        popoverTargetProps={{
          ...popoverTargetProps,
          "aria-controls": menuProps.id,
        }}
        query={query}
        resetOnQuery={false}
        resetOnSelect={false}
        selectedItems={selectedItems}
        tagInputProps={customizedTagInputProps}
        tagRenderer={tagRendererCallback}
        onActiveItemChange={handleActiveItemChange}
        onItemSelect={handleItemSelect}
        onQueryChange={setQuery}
      />
    </FormScope>
  );

  function renderTags(item: T) {
    if (tagless) {
      return null;
    }

    return tagRenderer(item);
  }

  function handleTagProps(_value: ReactNode, index: number): TagProps {
    const item = selectedItems[index];
    const resultTagProps = typeof tagProps === "function" && item
      ? tagProps(item, index)
      : {};

    return {
      minimal: tagMinimal,
      fill: tagFill,
      ...resultTagProps,
    };
  }

  function handleActiveItemChange(activeItem: T | null, isCreateNewItem: boolean) {
    setActiveItem(isCreateNewItem ? Core.getCreateNewItem() : activeItem);
  }

  function listOptionPropFactory(item: T) {
    return {
      shouldDismissPopover: false,
      selected: selectedItems.some(i => Core.executeItemsEqual(itemsEqual, i, item)),
    };
  }

  function renderList(listProps: Core.ItemListRendererProps<T>) {
    const extendedProps = extendItemListRendererProps<T, MultiSelectListRendererProps<T>>({ selectedItems, ...listProps }, groupRenderer, noResults, initialContent);
    extendedProps.menuProps = { ...extendedProps.menuProps, ...menuProps };

    const list = itemListRenderer(extendedProps);

    if (list === null) {
      return null;
    }

    return (
      <FormScope controlId="listbox">
        {list}
      </FormScope>
    );
  }

  function defaultItemListRenderer(listProps: MultiSelectListRendererProps<T>) {
    const createItem = listProps.renderCreateItem();
    const maybeNoResults = !createItem && listProps.filteredItems.length === 0 ? noResults : null;
    const menuContent = listProps.renderItems(maybeNoResults, initialContent);

    if (!menuContent && !createItem) {
      return null;
    }

    return (
      <Menu {...listProps.menuProps} {...menuProps} ulRef={listProps.itemsParentRef}>
        {menuContent}
        {createItem}
      </Menu>
    );
  }

  function handleItemSelect(item: T, event?: React.SyntheticEvent<HTMLElement>) {
    onItemSelect?.(item, event);

    // Remove if already in the list
    const index = selectedItems.findIndex(i => Core.executeItemsEqual(itemsEqual, i, item));
    if (index !== -1) {
      removeItem(index);
    } else {
      addItem(item);
    }
  }

  function handleItemRemove(value: React.ReactNode, index: number) {
    restTagInputProps.onRemove?.(value, index);
    removeItem(index);
  }

  function addItem(item: T) {
    onItemAdd?.(item);
    updateSelectedItems([...selectedItems, item]);
  }

  function removeItem(index: number) {
    const item = selectedItems[index];

    if (!item) {
      return;
    }

    onItemRemove?.(item);
    updateSelectedItems(selectedItems.filter((_, i) => i !== index));
  }

  function handleClear(): void {
    updateSelectedItems([]);
    setQuery("");
  }

  function updateSelectedItems(selectedItems: T[]) {
    if (!field?.readOnly && !field?.disabled) {
      field?.onChange(selectedItems);
      field?.onTouched();
    }

    onSelectedItemsChange?.(selectedItems);
    setUncontrolledSelectedItems(selectedItems);
  }

  function handlePopoverOpening(node: HTMLElement) {
    popoverProps?.onOpening?.(node);
    setActiveItem(null);
  }

  function handlePopoverClosing(node: HTMLElement) {
    popoverProps?.onClosing?.(node);

    if (!field?.readOnly && !field?.disabled) {
      field?.onTouched();
    }

    if (resetOnBlur) {
      setActiveItem(null);
      setQuery("");
    }
  }
}
