import { Fragment, useMemo } from "react";
import { Edit } from "@remhealth/icons";
import { LocalDate, Practitioner, Supervisor } from "@remhealth/apollo";
import { DateFormats, Ellipsize, FormScope, IconButton } from "@remhealth/ui";
import { isDateRangeEffective, useProductFlag, useReleaseCheck, useUserSession } from "@remhealth/host";
import { Avatar } from "~/avatars/avatar";
import { PersonName } from "~/avatars/personName";
import { PractitionerAvatar } from "~/avatars/practitionerAvatar";
import { PractitionerName } from "~/avatars/practitionerName";
import { UserTags, getUserCredentials, useProfileNav } from "~/users";
import { formatPhone } from "~/utils";
import { useExpandables, useStore } from "~/stores";
import {
  AvatarWrapper,
  DetailLabel,
  DetailValue,
  Details,
  Name,
  PractitionerButton,
  ProfileContainer,
  UserTagsRow
} from "./practitionerProfile.styles";

export interface PractitionerProfileProps {
  practitioner: Practitioner;
  showSupervisors?: boolean;
  onEditAvatarClick?: () => void;
}

export const PractitionerProfile = (props: PractitionerProfileProps) => {
  const { practitioner, showSupervisors, onEditAvatarClick } = props;

  const emails = practitioner.telecoms.filter(t => t.system === "Email" && isDateRangeEffective(t.effectivePeriod));
  const phones = practitioner.telecoms.filter(t => t.system === "Phone" && isDateRangeEffective(t.effectivePeriod));

  const store = useStore();
  const session = useUserSession();
  const { openProfile } = useProfileNav();
  const supervisors = useExpandables(store.practitioners, showSupervisors ? practitioner.supervisors.map(s => s.subject) : []);

  const hasWorkGroups = useProductFlag("HasWorkgroups");
  const isMyEvolvWorkgroupUpdateReleased = useReleaseCheck("myEvolvWorkgroupUpdate");

  const isAdmin = session.permissions.hasFlag("ClinicalFlags", "PatientPopulationAll");

  const workgroups = useMemo(getWorkgroups, [practitioner]);

  return (
    <FormScope controlId={`Profile for ${practitioner.display}`}>
      <ProfileContainer>
        <AvatarWrapper className="avatar-wrapper">
          <Avatar className="avatar" person={practitioner} size={100} />
          {onEditAvatarClick && (
            <IconButton elevated aria-label="Edit Photo" className="edit-avatar" icon={<Edit />} iconSize={16} intent="primary" onClick={onEditAvatarClick} />
          )}
        </AvatarWrapper>
        <Name>
          <Ellipsize lines={3}><PersonName name={practitioner.name} /></Ellipsize>
        </Name>
        <UserTagsRow className="user-tags">
          <UserTags user={practitioner} />
        </UserTagsRow>
        <Details className="details">
          {emails && emails.length > 0 && (
            <>
              <DetailLabel>Email</DetailLabel>
              <DetailValue>
                {emails.map((email, index) => (
                  <div key={index}><Ellipsize maxWidth={400}>{email.value}</Ellipsize></div>
                ))}
              </DetailValue>
            </>
          )}
          {phones && phones.length > 0 && (
            <>
              <DetailLabel>Phone</DetailLabel>
              <DetailValue>
                {phones.map((phone, index) => (
                  <div key={index}><Ellipsize maxWidth={400}>{formatPhone(phone.value)}</Ellipsize></div>
                ))}
              </DetailValue>
            </>
          )}
          {practitioner.tenure.start && (
            <>
              <DetailLabel>Hire date</DetailLabel>
              <DetailValue>
                {DateFormats.date(LocalDate.toDate(practitioner.tenure.start))}
                {practitioner.tenure.end && (
                  <> - {DateFormats.date(LocalDate.toDate(practitioner.tenure.end))}</>
                )}
              </DetailValue>
            </>
          )}
          {practitioner.qualifications.length > 0 && (
            <>
              <DetailLabel>Credentials</DetailLabel>
              <DetailValue>
                {getUserCredentials(session.practice, practitioner)}
              </DetailValue>
            </>
          )}
          {showSupervisors && practitioner.supervisors.map((supervisor, index) => (
            <Fragment key={index}>
              <DetailLabel>
                {practitioner.supervisors.length > 1 ? `${supervisor.role} ` : ""}
                Supervisor
              </DetailLabel>
              <DetailValue>{renderSupervisor(supervisor)}</DetailValue>
            </Fragment>
          ))}
          {hasWorkGroups && (isMyEvolvWorkgroupUpdateReleased ? isAdmin : true) && workgroups.length > 0 && (
            <>
              <DetailLabel>Workgroups</DetailLabel>
              <DetailValue>
                {workgroups.map((workgroup, index) => (
                  <div key={index}>{workgroup}</div>
                ))}
              </DetailValue>
            </>
          )}
        </Details>
      </ProfileContainer>
    </FormScope>
  );

  function renderSupervisor(supervisor: Supervisor) {
    const subject = supervisors.get(supervisor.subject.id);
    const name = subject?.display ?? supervisor.subject.display ?? "";

    if (!name) {
      return null;
    }

    return (
      <PractitionerButton
        minimal
        icon={subject ? <PractitionerAvatar practitioner={subject} size={28} /> : undefined}
        label={name}
        onClick={() => handleSupervisorClick(supervisor)}
      >
        {subject ? <PractitionerName practitioner={subject} /> : supervisor.subject.display}
      </PractitionerButton>
    );
  }

  function handleSupervisorClick(supervisor: Supervisor) {
    openProfile(supervisor.subject);
  }

  function getWorkgroups() {
    return practitioner.groupMemberships
      .filter(m => isDateRangeEffective(m.effective))
      .flatMap(m => m.listing.display ?? [])
      .sort()
  }
};
