import { useEffect, useState } from "react";

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(() => isMatch(query));

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    const nextIsMatch = isMatch(query);
    if (matches !== nextIsMatch) {
      setMatches(nextIsMatch);
    }

    matchMedia.addEventListener("change", handleMatchMediaChange);
    return () => {
      matchMedia.removeEventListener("change", handleMatchMediaChange);
    };
  }, [query]);

  return matches;

  function handleMatchMediaChange(event: MediaQueryListEvent) {
    setMatches(event.matches);
  }

  function isMatch(query: string) {
    return window.matchMedia(query).matches;
  }
}
