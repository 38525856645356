import { ContactMethod, ContactPoint, ContactPointUse, LocalDate, Person, Practice, Practitioner } from "@remhealth/apollo";
import { getProductFlag, isDateRangeEffective } from "@remhealth/host";

export function getUserNameAndCredentials(practice: Practice, user: Practitioner): string {
  const credentials = getUserCredentials(practice, user);
  return `${user.name.display}${credentials ? `, ${credentials}` : ""}`;
}

export function getUserCredentials(practice: Practice, user: Practitioner): string {
  return user.qualifications.length > 0
    ? getProductFlag(practice, "QualificationTitle")
      ? user.qualifications.flatMap(q => q.title ?? []).join(", ")
      : user.qualifications.map(q => q.abbreviation).join(", ")
    : "";
}

export function getUserEmail(user: Person): string | undefined {
  const emails = sortUserEmails(user).sort(sortContactPoint);
  return emails[0]?.value;
}

export function sortUserEmails(user: Person): ContactPoint[] {
  return user.telecoms
    .filter(t => t.system === ContactMethod.Email && isDateRangeEffective(t.effectivePeriod))
    .sort(sortContactPoint);
}

export function cleanNameForImport(fullName: string): string {
  // Assume name is Last, First {Middle}
  const parts = fullName.split(", ");
  if (parts.length === 2) {
    const [lastName, firstMiddle] = parts;
    const firstName = firstMiddle.split(" ")[0];
    const lastOnly = lastName.split(/[ -]/)[0] // Check for dashes or spaces in the last name
    return `${lastOnly}, ${firstName}`;
  }

  return fullName; // Invalid format, return as is
}

function sortContactPoint(left: ContactPoint, right: ContactPoint): number {
  const leftPreferred = left.preferred ? 1 : 0;
  const rightPreferred = right.preferred ? 1 : 0;
  if (leftPreferred !== rightPreferred) {
    return rightPreferred - leftPreferred;
  }

  // Work is most preferred
  const leftWork = left.use === ContactPointUse.Work ? 1 : 0;
  const rightWork = right.use === ContactPointUse.Work ? 1 : 0;
  if (leftWork !== rightWork) {
    return rightWork - leftWork;
  }

  // Temp is least preferred (even less preferred than undefined)
  const leftTemp = left.use === ContactPointUse.Temp ? 0 : 1;
  const rightTemp = right.use === ContactPointUse.Temp ? 0 : 1;
  if (leftTemp !== rightTemp) {
    return rightTemp - leftTemp;
  }

  const leftExpiration = left.effectivePeriod?.end ?? LocalDate.create(2100, 1, 1);
  const rightExpiration = right.effectivePeriod?.end ?? LocalDate.create(2100, 1, 1);

  if (leftExpiration !== rightExpiration) {
    return rightExpiration.localeCompare(leftExpiration);
  }

  const leftEffective = left.effectivePeriod?.start ?? LocalDate.create(1900, 1, 1);
  const rightEffective = right.effectivePeriod?.start ?? LocalDate.create(1900, 1, 1);

  if (leftEffective !== rightEffective) {
    return rightEffective.localeCompare(leftEffective);
  }

  return 0;
}
