import { values } from "lodash-es";
import {
  AdditionalService,
  CarePlanActivity,
  CarePlanOutcome,
  CodeableConcept,
  Coding,
  Condition,
  Diagnosis,
  Duration,
  EncounterDocumentationType,
  EvidenceBasedPracticeCoding,
  GoalAchievementStatus,
  GoalElementActivity,
  GoalElementType,
  HealthcareService,
  type MultiNote,
  NoteSection,
  NoteSectionFormat,
  Organization,
  PatientNote,
  ProblemActivity,
  ProgramEnrollment,
  ProgressNoteCustomOption,
  Reference,
  Referenceable,
  RichText,
  SessionTimeFormat,
  VisitStatus,
  isGroupNote
} from "@remhealth/apollo";
import { type Complete, Labeling, Yup } from "@remhealth/core";
import { Text } from "~/text";
import {
  DraftSignature,
  FormNoteSection,
  NoteForm,
  PatientSignature,
  Session,
  TextNoteSection,
  ValidationMode
} from "~/notes/types";
import { QuestionnaireContext, getQuestionnaireContextHashKey } from "~/questionnaire/contexts";
import { formNoteSectionSchema } from "~/questionnaire/formNoteSectionSchema";
import { getVisitStatus, renderVisitStatus } from "~/utils";
import { validateCarePlanActivities } from "~/goals/utils";
import { isCompleteSignature, isGoalElementAtLeastLevel } from "../utils";
import { sessionsSchema } from "./sessionsSchema";
import { sectionSchema } from "./sectionSchema";

export interface NoteFormSchemaOptions {
  note: PatientNote;
  multiNote: MultiNote | null;
  labels: Labeling;
  validationMode: ValidationMode;
  requireUnits: boolean;
  requireCategory: boolean;
  requireEnrollment: boolean;
  requireProgram: boolean;
  hasCarePlans: boolean;
  checkNoteTypeGoalsMinimumLevel: boolean;
  allowZeroDurationNonShowNotes: boolean;
  requireGroupServiceOverride: boolean;
  hideElementLinkIds: Set<string>;
}

export type NoteFormSchema = Pick<NoteForm,
  | "definition"
  | "period"
  | "patient"
  | "insurances"
  | "carePlans"
  | "diagnoses"
  | "episodeOfCareId"
  | "sessions"
  | "textSections"
  | "sessionTimeFormat"
  | "duration"
  | "invalidDuration"
  | "ctoneCustomDropdown"
  | "units"
  | "formSections"
  | "programEnrollment"
  | "location"
  | "locationRole"
  | "locationKind"
  | "serviceType"
  | "category"
  | "clinicalQualityCodes"
  | "status"
  | "evidenceBasedPracticesCodings"
  | "carePlanActivities"
  | "problems"
  | "additionalServices"
  | "patientSignatures"
>;

interface NoteFormSchemaContext {
  questionnaireContext: QuestionnaireContext;
  noteFormContext: {
    hasCarePlans: boolean;
    leafLevelGoalComments: boolean;
  };
}

// We need to create a different schema based on current values that affect CNS questions
// so we'll cache them for performance
const schemaCache = new Map<string, Yup.ObjectSchema<NoteFormSchema>>();

export function noteFormSchema(options: NoteFormSchemaOptions) {
  return Yup.lazy<NoteFormSchema>(noteForm => {
    const questionnaireContext: QuestionnaireContext = {
      patient: noteForm.patient?.resource,
      visitDate: noteForm.period.start,
      insurances: noteForm.insurances,
      serviceType: noteForm.serviceType,
      program: noteForm.programEnrollment?.program,
      location: noteForm.location,
      locationRole: noteForm.locationRole,
      serviceLocation: noteForm.locationKind,
      composition: options.note.partOfComposition ?? options.note.composition,
      narrativeOverrideLinkId: options.note.definition.resource?.narrativeDestination,
      hideElementLinkIds: options.hideElementLinkIds,
      period: noteForm.period,
    };

    const schemaKey = [
      getQuestionnaireContextHashKey(questionnaireContext),
      getSchemaHashKey(options),
    ].join("|");

    let schema = schemaCache.get(schemaKey);

    if (!schema) {
      const context: NoteFormSchemaContext = {
        questionnaireContext,
        noteFormContext: {
          hasCarePlans: options.hasCarePlans,
          leafLevelGoalComments: noteForm.definition.leafLevelGoalComments,
        },
      };

      schema = inferNoteFormSchema(context, options);
      schemaCache.set(schemaKey, schema);
    }

    return schema;
  });
}

const blankNoteFormSchemaOptions: Record<keyof NoteFormSchemaOptions, undefined> = {
  note: undefined,
  multiNote: undefined,
  labels: undefined,
  validationMode: undefined,
  requireUnits: undefined,
  requireCategory: undefined,
  requireEnrollment: undefined,
  requireProgram: undefined,
  hasCarePlans: undefined,
  checkNoteTypeGoalsMinimumLevel: undefined,
  allowZeroDurationNonShowNotes: undefined,
  requireGroupServiceOverride: undefined,
  hideElementLinkIds: undefined,
} as const;

const hashKeys = Object.keys(blankNoteFormSchemaOptions) as (keyof typeof blankNoteFormSchemaOptions)[];
export function getSchemaHashKey(options: NoteFormSchemaOptions) {
  const hashValues = hashKeys.map(key => getHashValue(options, key));
  return hashValues.join("|");
}

// Ensures we always have a serializable QuestionnaireContext
function getHashValue(options: Partial<NoteFormSchemaOptions>, key: keyof NoteFormSchemaOptions): string {
  const value = options[key];

  if (value === null) {
    return "";
  }

  switch (typeof value) {
    case "string": return value;
    case "boolean": return `${value}`;
    case "undefined": return "";
    case "object":
      if ("Product" in value) {
        return value.Product;
      }

      if (value instanceof Set) {
        return [...value.values()].join("|");
      }

      return value.sections.flatMap(section => [
        // List all properties that effect validation
        `${section.name}`,
        `${section.required}`,
        `${"requireGoalsWhenPresent" in section ? section.requireGoalsWhenPresent : ""}`,
        `${"requiredGoalElementType" in section ? section.requiredGoalElementType : ""}`,
        `${"requireComments" in section ? section.requireComments : ""}`,
        `${"filterGoals" in section ? section.filterGoals : ""}`,
        `${"diagnosisLimit" in section ? section.diagnosisLimit : ""}`,
        section.form?.versionId,
      ]).join("|");
  }
}

function inferNoteFormSchema(context: NoteFormSchemaContext, options: NoteFormSchemaOptions) {
  const {
    note,
    labels,
    validationMode,
    requireUnits,
    requireCategory,
    requireProgram,
    requireEnrollment,
    checkNoteTypeGoalsMinimumLevel,
    requireGroupServiceOverride,
  } = options;

  const questionnaireContext = context.questionnaireContext;
  const goalsSection = note.sections.find(s => s.format === NoteSectionFormat.GoalsObjectives);
  const diagnosisSection = note.sections.find(s => s.format === NoteSectionFormat.Diagnosis);
  const sessionTimeSection = note.sections.find(s => s.format === NoteSectionFormat.SessionTime);
  const ctoneCustomDropdownSection = note.sections.find(s => s.format === NoteSectionFormat.CtoneCustomDropdown);
  const clinicalQualityCodesSection = note.sections.find(s => s.format === NoteSectionFormat.ClinicalQualityIndicator);
  const evidenceBasedPracticesSection = note.sections.find(s => s.format === NoteSectionFormat.EvidenceBasedPractices);
  const goalTrackingSectionSection = note.sections.find(s => s.format === NoteSectionFormat.GoalTracking);
  const problemListSection = note.sections.find(s => s.format === NoteSectionFormat.Problems);
  const additionalServices = note.sections.find(s => s.format === NoteSectionFormat.AddOn);

  return Yup.object<NoteFormSchema>({
    location: Yup.mixed(),
    locationRole: Yup.mixed(),
    locationKind: Yup.mixed(),
    definition: Yup.mixed(),
    patient: Yup.mixed(),
    period: Yup.mixed(),
    insurances: Yup.array(Yup.mixed<Reference<Organization>>()),
    ctoneCustomDropdown: ctoneCustomDropdownSectionSchema(isEnforceRequired(validationMode, ctoneCustomDropdownSection), ctoneCustomDropdownSection),
    clinicalQualityCodes: clinicalQualityCodesSectionSchema(isEnforceRequired(validationMode, clinicalQualityCodesSection), clinicalQualityCodesSection),
    carePlans: carePlansSchema(isEnforceRequired(validationMode, goalsSection), goalsSection, context.noteFormContext.hasCarePlans, checkNoteTypeGoalsMinimumLevel, context.noteFormContext.leafLevelGoalComments).label("Goals"),
    diagnoses: diagnosisSchema(isEnforceRequired(validationMode, diagnosisSection), diagnosisSection).label("Diagnosis"),
    evidenceBasedPracticesCodings: evidenceBasedPracticesSectionSchema(isEnforceRequired(validationMode, evidenceBasedPracticesSection), evidenceBasedPracticesSection),
    textSections: Yup.array<TextNoteSection>(Yup.lazy<TextNoteSection>(textSection => sectionSchema(textSection, isEnforceRequired(validationMode, textSection)))),
    episodeOfCareId: Yup.string().label(labels.Enrollment).requiredWhen(validationMode !== "loose" && requireEnrollment),
    sessionTimeFormat: Yup.mixed<SessionTimeFormat>().notRequired(),
    sessions: Yup.array<Session>().when("sessionTimeFormat", {
      is: "Duration",
      then: sessionsSchema(isEnforceRequired(validationMode, sessionTimeSection), options.allowZeroDurationNonShowNotes, "Duration", sessionTimeSection),
      otherwise: sessionsSchema(isEnforceRequired(validationMode, sessionTimeSection), options.allowZeroDurationNonShowNotes, "TimeIncrements", sessionTimeSection),
    }),
    duration: Yup.mixed<Duration | undefined>().when("sessionTimeFormat", {
      is: "Duration",
      then: durationSchema(isEnforceRequired(validationMode, sessionTimeSection), "Duration", sessionTimeSection),
      otherwise: durationSchema(isEnforceRequired(validationMode, sessionTimeSection), "TimeIncrements", sessionTimeSection),
    }),
    invalidDuration: Yup.boolean().test("Invalid Duration", "Invalid Duration", function(value: boolean) {
      if (value) {
        return this.createError({ path: this.path, message: `${Text.LengthOfSession} time is Invalid` });
      }
      return true;
    }),
    units: unitsSchema(requireUnits, !!sessionTimeSection),
    formSections: Yup.array<FormNoteSection>(formSectionsSchema(questionnaireContext, note, validationMode, labels)),
    programEnrollment: Yup.mixed<ProgramEnrollment>().label(Text.Program).requiredWhen(validationMode !== "loose" && requireProgram),
    serviceType: Yup.mixed<HealthcareService>()
      .label(labels.ServiceType)
      .requiredWhen(note.encounter.resource?.documentationType === "Note")
      .test("Service Type Overlap", "Service Type Overlap", function(value: HealthcareService | undefined) {
        const groupServiceType = note.partOf?.resource?.encounter.resource?.serviceType;
        if (!groupServiceType || !value) {
          return true;
        }
        const definitionAssignmentServices = note.partOf?.resource?.definitionAssignments.filter(i => i.service).map(i => i.service!.id) ?? [];
        const isRestrictedService = [...definitionAssignmentServices, groupServiceType?.id].includes(value.id);
        const visitStatus = getVisitStatus(note.encounter.resource?.status);
        if (requireGroupServiceOverride && isGroupNote(note.partOf?.resource) && isRestrictedService && isEnforceRequired(validationMode, undefined)) {
          return this.createError({ path: this.path, message: Text.ForceServiceOverride(labels, renderVisitStatus(visitStatus)) });
        }
        return true;
      }),
    category: Yup.mixed<Coding | undefined>().label(labels.NoteCategory).requiredWhen(validationMode !== "loose" && requireCategory),
    status: Yup.mixed<VisitStatus>().required().oneOf(Object.values(VisitStatus)),
    carePlanActivities: carePlanActivitiesSchema(isCarePlanActivitiesRequired(note.derivedFrom?.resource, validationMode, goalTrackingSectionSection), goalTrackingSectionSection),
    problems: problemsSchema(isEnforceRequired(validationMode, problemListSection), problemListSection, labels).label(Text.ProblemList(labels)),
    additionalServices: additionalServiceSchema(isEnforceRequired(validationMode, additionalServices) && note.encounter.resource?.documentationType === EncounterDocumentationType.Note, additionalServices)
      .label("Add-on"),
    patientSignatures: Yup.array<DraftSignature | PatientSignature>(patientSignatureSchema(validationMode === "strict")).label("Signatures"),
  });
}

function problemsSchema(enforceRequired: boolean, section: NoteSection | undefined, labels: Labeling): Yup.ArraySchema<ProblemActivity> {
  let schema = Yup.array<Complete<ProblemActivity>>(
    Yup.object<Complete<ProblemActivity>>({
      comments: Yup.mixed<RichText>()
        .label("Comments")
        .notRequired()
        .test("max-length", "Comments must be at most 100 characters.", function(value: RichText | undefined) {
          return !value?.plainText || value.plainText.length <= 100;
        }),
      problem: Yup.mixed<Referenceable<Condition>>()
        .label(labels.Problem)
        .test("required", `${labels.Problem} is required.`, function(value: Referenceable<Condition> | undefined) {
          return !!value?.display?.trim();
        }),
      extensions: Yup.mixed(),
      identifiers: Yup.mixed(),
    })
  );
  if (enforceRequired && section?.required) {
    schema = schema.min(1, `${section.name} is required`);
  }
  return schema;
}

function carePlanActivitiesSchema(enforceRequired: boolean, section: NoteSection | undefined): Yup.ArraySchema<CarePlanActivity> {
  let schema = Yup.array<Complete<CarePlanActivity>>(
    Yup.object<Complete<CarePlanActivity>>({
      activity: Yup.array<Complete<GoalElementActivity>>(createGoalElementSchema(1, enforceRequired)),
      carePlan: Yup.mixed(),
    })
  );
  if (enforceRequired && section?.required) {
    schema = schema.min(1, `${section.name} is required`);
  }
  return schema;
}

function createGoalElementSchema(level: number, enforceRequired: boolean): Yup.ObjectSchema<Complete<GoalElementActivity>> {
  return Yup.object<Complete<GoalElementActivity>>({
    achievementStatus: Yup.mixed<GoalAchievementStatus>().label("Achieved").requiredWhen(level === 2 && enforceRequired).oneOf(values(GoalAchievementStatus)), // For ct1, it is expected to be at the 2nd level only (Goal > Objective) & our UI is built to only present these dropdowns at the 2nd level
    addresses: Yup.mixed<Reference<Condition>>(),
    elementType: Yup.mixed<GoalElementType>(),
    lifecycleStatusCode: Yup.mixed<Coding>(),
    code: Yup.mixed<CodeableConcept>(),
    detail: Yup.string().notRequired().max(2000),
    extensions: Yup.mixed(),
    identifiers: Yup.mixed(),
    levelOfAssistance: Yup.mixed<CodeableConcept>().label("Level of Assistance").requiredWhen(level === 2 && enforceRequired),
    linkId: Yup.string(),
    numberOfPromptsCode: Yup.mixed<Coding>().label("Number of Prompts").requiredWhen(level === 2 && enforceRequired),
    comments: Yup.mixed<RichText>()
      .label("Comments")
      .notRequired()
      .test("max-length", "Comments must be at most 100 characters.", function(value: RichText | undefined) {
        return !value?.plainText || value.plainText.length <= 100;
      }),
    // Use Yup.lazy to avoid infinite loop
    children: Yup.array().of(Yup.lazy(() => createGoalElementSchema(level + 1, enforceRequired))),
  });
}

function formSectionsSchema(questionnaireContext: QuestionnaireContext, note: PatientNote, validationMode: ValidationMode, labels: Labeling) {
  const forms = note.sections.flatMap(s => s.form?.resource ?? []);
  return formNoteSectionSchema({ forms, questionnaireContext }, validationMode, labels);
}

function ctoneCustomDropdownSectionSchema(enforceRequired: boolean, section: NoteSection | undefined): Yup.ObjectSchema<ProgressNoteCustomOption> {
  return Yup.object<ProgressNoteCustomOption>()
    .test("Section required", "Section required", function(value?: ProgressNoteCustomOption) {
      if (section?.required && enforceRequired) {
        if (!value) {
          return this.createError({ path: this.path, message: `${section.name} is a required field` });
        }
      }
      return true;
    });
}

function clinicalQualityCodesSectionSchema(enforceRequired: boolean, section: NoteSection | undefined): Yup.ArraySchema<ProgressNoteCustomOption> {
  let schema = Yup.array<ProgressNoteCustomOption>();
  if (enforceRequired && section?.required) {
    schema = schema.min(1, `${section.name} is required`);
  }
  return schema;
}

function carePlansSchema(enforceRequired: boolean, section: NoteSection | undefined, hasCarePlans: boolean, checkNoteTypeGoalsMinimumLevel: boolean, leafLevelGoalComments: boolean): Yup.ArraySchema<CarePlanActivity> {
  let schema = Yup.array<CarePlanActivity>()
    .test("NoteTypeGoalsMinimumLevel", "Note type goals minimum level required", function(value?: CarePlanActivity[]) {
      if (section && checkNoteTypeGoalsMinimumLevel && enforceRequired && value && value.length > 0) {
        if (!verifyNoteTypeGoalsMinimumLevel(value, section.requiredGoalElementType)) {
          return this.createError({ path: this.path, message: `${section.requiredGoalElementType} is the required minimum level` });
        }
      }
      return true;
    })
    .test("GoalComment", "Comment for goals is required", function(value?: CarePlanActivity[]) {
      if (enforceRequired && !validateCarePlanActivities(value ?? [], leafLevelGoalComments, section?.requireComments ?? false)) {
        return this.createError({ path: this.path, message: "Goal comment is required and is missing for one or more goals." });
      }
      return true;
    });

  if (enforceRequired) {
    if (section?.requireGoalsWhenPresent) {
      if (hasCarePlans) {
        schema = schema.min(1, `${section?.name} is required`);
      }
    } else if (section?.required) {
      schema = schema.min(1, `${section?.name} is required`);
    }
  }
  return schema;
}

function verifyNoteTypeGoalsMinimumLevel(carePlanActivities: CarePlanActivity[], minimumLevel: GoalElementType) {
  return carePlanActivities.every(cpa => cpa.activity.every(a => isGoalElementAtLeastLevel(a, minimumLevel, GoalElementType.Goal)));
}

function diagnosisSchema(enforceRequired: boolean, section: NoteSection | undefined): Yup.ArraySchema<Diagnosis> {
  let schema = Yup.array<Diagnosis>();
  if (enforceRequired && section?.required) {
    schema = schema.min(1, `${section.name} is required`);
  }
  if (enforceRequired && section?.diagnosisLimit === "RestrictToOne") {
    schema = schema.max(1, "Only one diagnosis selection is allowed for this note.");
  }
  return schema;
}

function additionalServiceSchema(enforceRequired: boolean, section: NoteSection | undefined): Yup.ArraySchema<Complete<AdditionalService>> {
  let schema = Yup.array<Complete<AdditionalService>>(
    Yup.object<Complete<AdditionalService>>({
      duration: Yup.mixed<Duration>().label("duration").test("max-duration", "Duration", function(value: Duration | undefined) {
        const additionalService = this.parent as AdditionalService;
        const isDurationRequired = additionalService.extensions?.some(b => b.name === "requiresDuration" && b.value === "true");
        if (value === undefined && enforceRequired && isDurationRequired) {
          return this.createError({ message: "Duration is required" });
        }
        if (value && Duration.toLuxon(value).as("minutes") === 0) {
          return this.createError({ message: "Duration should be greater than zero." });
        }
        if (value && Duration.toLuxon(value).as("minutes") > 999) {
          return this.createError({ message: "Duration should be less than 999 minutes." });
        }
        return true;
      }),
      coding: Yup.mixed<Coding>().requiredWhen(enforceRequired).label("Add-on service"),
      comment: Yup.mixed<RichText>()
        .label("Comments")
        .notRequired()
        .test("max-length", "Comments must be at most 100 characters.", function(value: RichText | undefined) {
          return !value?.plainText || value.plainText.length <= 100;
        }),
      extensions: Yup.mixed(),
      identifiers: Yup.mixed(),
    })
  );

  if (enforceRequired && section?.required) {
    schema = schema.min(1, `${section.name} is required`);
  }
  return schema;
}

function durationSchema(enforceRequired: boolean, sessionTimeFormat: SessionTimeFormat, section: NoteSection | undefined): Yup.MixedSchema<Duration | undefined> {
  let schema = Yup.mixed<Duration | undefined>();
  if (enforceRequired && section?.required) {
    if (sessionTimeFormat === SessionTimeFormat.Duration) {
      schema = Yup.mixed<Duration | undefined>()
        .test("Length of session", "Length of session", function(value: Duration | undefined) {
          const noteForm = this.parent as NoteFormSchema;
          const requireDuration = noteForm.status === "Show";
          if (requireDuration) {
            if (value === undefined || Duration.toLuxon(value).as("minutes") === 0) {
              return this.createError({ message: `${Text.LengthOfSession} should be greater than zero.` });
            }
          }
          return true;
        });
    } else {
      schema = Yup.mixed<Duration | undefined>()
        .test("Length of session", "Length of session", function(value: Duration | undefined) {
          if (value === undefined || Duration.toLuxon(value).as("hours") >= 24) {
            return this.createError({ message: `${Text.LengthOfSession} should be less than 24 hrs.` });
          }
          return true;
        });
    }
  }
  return schema;
}

function unitsSchema(requireUnits = false, hasSessionTimeSection = false): Yup.NumberSchema<number | undefined> {
  let schema = Yup.number().notRequired();
  const schemaWithSessionTimeSection = Yup.number().label(Text.Units).moreThan(0)
    .test("2 of decimals allowed", `${Text.Units} allow at most 2 digits after decimal.`, function(u: number | undefined) {
      return u === undefined || /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/.test(u.toString());
    });

  if (requireUnits && hasSessionTimeSection) {
    schema = schemaWithSessionTimeSection.required();
  }
  return schema;
}

function evidenceBasedPracticesSectionSchema(enforceRequired: boolean, section?: NoteSection): Yup.ArraySchema<EvidenceBasedPracticeCoding> {
  let schema = Yup.array<EvidenceBasedPracticeCoding>();
  if (enforceRequired && section?.required) {
    if (section.useEvidenceBasedPracticeElements) {
      schema = Yup.array<EvidenceBasedPracticeCoding>(
        Yup.object<EvidenceBasedPracticeCoding>({
          code: Yup.string(),
          system: Yup.string(),
          elements: Yup.array<Coding>().min(1, "Elements are required"),
        })
      ).min(1, `${Text.EvidenceBasedPractices} is required`);
    } else {
      schema = schema.min(1, `${Text.EvidenceBasedPractices} is required`);
    }
  }
  return schema;
}

function patientSignatureSchema(enforceRequired: boolean): Yup.ObjectSchema<DraftSignature | PatientSignature> {
  return Yup.object<PatientSignature | DraftSignature>()
    .test("required", "Signature is required", function(signature: DraftSignature | PatientSignature | undefined) {
      if (!signature) {
        return true;
      }

      return !enforceRequired || !signature.required || isCompleteSignature(signature);
    });
}

function isEnforceRequired(validationMode: ValidationMode, section: NoteSection | TextNoteSection | undefined) {
  if (validationMode === "strict") {
    return true;
  }
  if (section) {
    return validationMode === "patient-viewables" && !!section?.includeInPatientView;
  }
  return validationMode === "patient-viewables";
}

function isCarePlanActivitiesRequired(carePlanOutcome: CarePlanOutcome | undefined, validationMode: ValidationMode, goalTrackingSectionSection: NoteSection | undefined): boolean {
  if (carePlanOutcome?.partOf) {
    return false;
  }
  return isEnforceRequired(validationMode, goalTrackingSectionSection);
}
