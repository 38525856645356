import { memo, useState } from "react";
import { isEqual, omit } from "lodash-es";
import styled from "styled-components";
import { Issue, ResetLight, TickCircle } from "@remhealth/icons";
import { PatientNote } from "@remhealth/apollo";
import { useUserSession } from "@remhealth/host";
import { AnchorButton, Icon, IconButton, Tooltip, useDebouncedSubscriptionState } from "@remhealth/ui";
import { Text } from "~/text";
import { NoteReviewContext } from "~/notes/hooks";

const TextButton = styled(AnchorButton)`
  margin: 0px 12px;
  white-space: nowrap;

  .bp5-button-text {
    line-height: normal;
  }
`;

export interface NoteReviewButtonProps {
  note: PatientNote;
  reviewed: boolean;
  iconOnly?: boolean;
  loading?: boolean;
  invalidReason?: string;
  "aria-label"?: string;
  name?: string;
  onReview: () => Promise<void>;
}

export const NoteReviewButton = memo((props: NoteReviewButtonProps) => {
  const {
    note,
    iconOnly,
    reviewed,
    "aria-label": ariaLabel = "Review Note",
    name,
    onReview,
  } = props;

  const user = useUserSession();
  const [reviewing, setReviewing] = useState(false);
  const noteReviewState = useDebouncedSubscriptionState(NoteReviewContext, 100);

  const loading = props.loading || reviewing;

  const noteWarning = noteReviewState.noteWarnings.get(note.id);
  const invalidReason = props.invalidReason ?? noteWarning?.message;

  if (note.status === "Final") {
    return (
      <Tooltip content="Signed">
        <Icon color="var(--gray4)" icon="tick" size={14} />
      </Tooltip>
    );
  }

  const isReadOnly = !note.participants.some(a => a.role === "PrimaryPerformer" && a.individual.id === user.person.id);

  if (isReadOnly) {
    return null;
  }

  const common = {
    "aria-label": ariaLabel,
    "className": "review-button",
    name,
  };

  if (reviewed) {
    return (
      <Tooltip content="Reviewed">
        {iconOnly
          ? <IconButton minimal icon={<TickCircle />} intent="success" loading={loading} onClick={handleReview} {...common} />
          : <TextButton outlined icon={<ResetLight />} intent="primary" label={Text.MarkNotReviewed} loading={loading} onClick={handleReview} {...common} />}
      </Tooltip>
    );
  }

  if (invalidReason) {
    const intent = noteWarning && !loading ? noteWarning.warning ? "warning" : "danger" : "none";
    const icon = noteWarning?.icon ?? <Issue />;
    return (
      <Tooltip content={invalidReason}>
        {iconOnly
          ? <IconButton minimal icon={icon} intent={intent} loading={loading} onClick={handleReview} {...common} />
          : <TextButton outlined icon={icon} label={Text.MarkReviewed} loading={loading} onClick={handleReview} {...common} />}
      </Tooltip>
    );
  }

  return (
    <Tooltip className={loading ? undefined : "hoverable"} content="Not Reviewed">
      {iconOnly
        ? <IconButton minimal outlined icon="tick" iconSize={12} intent="primary" loading={loading} onClick={handleReview} {...common} />
        : <TextButton outlined icon="tick" intent="primary" label={Text.MarkReviewed} loading={loading} onClick={handleReview} {...common} />}
    </Tooltip>
  );

  async function handleReview() {
    setReviewing(true);
    try {
      await onReview();
    } finally {
      setReviewing(false);
    }
  }
}, arePropsEqual);

function arePropsEqual(prevProps: NoteReviewButtonProps, nextProps: NoteReviewButtonProps) {
  const omitProps: (keyof NoteReviewButtonProps)[] = ["onReview"];
  return isEqual(omit(prevProps, omitProps), omit(nextProps, omitProps));
}
