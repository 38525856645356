import Inline from "quill/blots/inline";
import { css } from "styled-components";

export interface DraftBlotData {
  revert: string;
}

export class DraftBlot extends Inline {
  public static readonly blotName = "draft";
  public static readonly tagName = "rh-draft";
  public readonly draftBlot = true;

  public static create(data: DraftBlotData) {
    const node = document.createElement(DraftBlot.tagName);
    if (data.revert) {
      node.setAttribute("revert", data.revert);
    }
    return node;
  }

  public static value(node: HTMLElement): DraftBlotData {
    return {
      revert: node.getAttribute("revert") ?? "",
    };
  }
}

export function isDraftBlot(blot: any): blot is DraftBlot {
  return blot && blot instanceof DraftBlot;
}

export const DraftCss = css`
  rh-draft {
    background-color: ${props => props.theme.input.background.primary};
  }
`;
