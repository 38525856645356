import { useContext, useState } from "react";
import { DateTime } from "luxon";
import { partition } from "lodash-es";
import { Approximate, LocalDate, NoteSectionForm, QuestionnaireAnswer, QuestionnaireAnswerOption, QuestionnaireCalculation, QuestionnaireDependency, QuestionnaireElement, Reference, ResourceTypes } from "@remhealth/apollo";
import { areCodingsSame, isDateRangeEffective, resourceEquals } from "@remhealth/host";
import { FormField, useDebouncedEffect, useSubscriptionDispatch } from "@remhealth/ui";
import { genderFilterCodings, isGenderIdentity } from "~/patient/utils";
import { NoteContext } from "~/notes/contexts";
import { type QuestionnaireAnswerContext, QuestionnaireContext, QuestionnaireFormAnswersContext, type QuestionnairePatient } from "./contexts";
import { answerValueHasValue, areSameAnswerValues, isAnswerOptionValue } from "./utils";
import { QuestionFlavor, resolveQuestionFlavor } from "./flavors";
import { computeCalculatedAnswer } from "./narrative";

export function useQuestionDependencies(form: NoteSectionForm, answers: FormField<QuestionnaireAnswer[]>) {
  const noteContext = useContext(NoteContext);
  const setStateAnswers = useSubscriptionDispatch(QuestionnaireFormAnswersContext);

  const [elements, setElements] = useState(() => getVisibleElements(answers));

  useDebouncedEffect(() => {
    setStateAnswers(answers.value);
    setElements(getVisibleElements(answers, true));
  }, 100, [noteContext, answers.value]);

  return elements;

  function getVisibleElements(answers: FormField<QuestionnaireAnswer[]>, canUpdate = false): QuestionnaireElement[] {
    const dependencies = getQuestionDependencies(form.elements, noteContext);
    const visibleQuestions = dependencies.getVisible(answers.value);

    const context: QuestionnaireAnswerContext = {
      getValuesByLinkId: (linkId: string) => answers.value.flatMap(v => v.linkId === linkId ? v.values : []),
    };

    return visibleQuestions.map(element => {
      // Update calculated answers
      if (canUpdate && resolveQuestionFlavor(element) === QuestionFlavor.Calculated) {
        const elementAnswers = answers.value.find(a => a.linkId === element.linkId)?.values ?? [];
        const computedValue = computeCalculatedAnswer(element, context);

        if (!areSameAnswerValues(elementAnswers[0], computedValue)) {
          const updatedAnswers = answers.value.filter(a => a.linkId !== element.linkId);
          if (computedValue) {
            updatedAnswers.push({ linkId: element.linkId, values: [computedValue] });
          }
          answers.onChange(updatedAnswers);
        }
      }

      if (element.answerOptions.length > 0) {
        const elementAnswers = answers.value.find(a => a.linkId === element.linkId)?.values ?? [];
        const [visibleOptions, invisibleOptions] = partition(element.answerOptions, option => dependencies.isOptionVisible(option, answers.value));

        if (canUpdate) {
          // Remove answered options that are no longer visible
          const validAnswers = elementAnswers.filter(answerValue => !invisibleOptions.some(option => isAnswerOptionValue(option, answerValue)));
          if (validAnswers.length !== elementAnswers.length) {
            const updatedAnswers = answers.value.filter(a => a.linkId !== element.linkId);
            updatedAnswers.push({ linkId: element.linkId, values: validAnswers });
            answers.onChange(updatedAnswers);
          }
        }

        // Remove Yes/No answer options from non-required Yes/No control if there aren't any dependencies
        // This causes it to appear as a checkbox instead
        if (form.mode !== "Narrative") {
          if (element.type === "Boolean" && !element.required) {
            const isExternalQuestion = element.identifiers && element.identifiers.length > 0;
            if (isExternalQuestion && !visibleOptions.some(dependencies.optionIsDependedOn)) {
              visibleOptions.length = 0;
            }
          }
        }

        return { ...element, answerOptions: visibleOptions };
      }

      return element;
    });
  }
}

export interface QuestionDependencies {
  isVisible(question: QuestionnaireElement, answers: QuestionnaireAnswer[]): boolean;
  isOptionVisible(option: QuestionnaireAnswerOption, answers: QuestionnaireAnswer[]): boolean;
  optionIsDependedOn(option: QuestionnaireAnswerOption): boolean;
  getVisible(answers: QuestionnaireAnswer[]): QuestionnaireElement[];
}

interface AnswerOptionDependency extends QuestionnaireAnswerOption {
  elementLinkId: string;
}

export function getQuestionDependencies(elements: QuestionnaireElement[], context: QuestionnaireContext): QuestionDependencies {
  const questionDependencyMap = new Map<string, Set<string>>();
  const optionDependencyMap = new Map<string, AnswerOptionDependency[]>();

  for (const element of elements) {
    for (const dependency of element.dependsOn) {
      recordDependency(element.linkId, dependency);
    }

    for (const option of element.answerOptions) {
      for (const dependency of option.dependsOn) {
        recordDependency(option.linkId, dependency);
      }
    }
  }

  return { isVisible, isOptionVisible, optionIsDependedOn, getVisible };

  function recordDependency(dependentLinkId: string, dependency: QuestionnaireDependency) {
    if (!dependency.answerOptionLinkId) {
      const dependencies = questionDependencyMap.get(dependentLinkId);
      if (!dependencies) {
        questionDependencyMap.set(dependentLinkId, new Set<string>([dependency.elementLinkId]));
      } else {
        dependencies.add(dependency.elementLinkId);
        questionDependencyMap.set(dependentLinkId, dependencies);
      }
    } else {
      const option = elements.find(e => e.linkId === dependency.elementLinkId)?.answerOptions.find(a => a.linkId === dependency.answerOptionLinkId);
      if (option) {
        const dependencies = optionDependencyMap.get(dependentLinkId);
        const optionDependency = { elementLinkId: dependency.elementLinkId, ...option };
        if (!dependencies) {
          optionDependencyMap.set(dependentLinkId, [optionDependency]);
        } else {
          dependencies.push(optionDependency);
          optionDependencyMap.set(dependentLinkId, dependencies);
        }
      }
    }
  }

  function isVisible(question: QuestionnaireElement, answers: QuestionnaireAnswer[]): boolean {
    if (!filterQuestionnaireElement(question, context)) {
      return false;
    }

    if (question.dependsOn.length === 0) {
      return true;
    }

    // If dependent on any answer given on the question
    const questionDependencies = questionDependencyMap.get(question.linkId);
    if (questionDependencies && questionDependencies.size > 0) {
      const { maxAnswers = Number.MAX_VALUE, minAnswers = 1 } = getRequiredAnswersCount(question);
      const dependenciesAnswers = answers.filter(a => questionDependencies.has(a.linkId) && a.values.some(answerValueHasValue));
      return dependenciesAnswers.length >= minAnswers && dependenciesAnswers.length <= maxAnswers;
    }

    // If dependent on specific answer options
    const optionDependencies = optionDependencyMap.get(question.linkId);
    if (optionDependencies && optionDependencies.length > 0) {
      return optionDependencies.some(optionDependency => answers.some(a => a.linkId === optionDependency.elementLinkId && a.values.some(value => isAnswerOptionValue(optionDependency, value))));
    }

    // Not dependent on any known element or option
    return true;
  }

  function isOptionVisible(option: QuestionnaireAnswerOption, answers: QuestionnaireAnswer[]): boolean {
    if (!filterAnswerOption(option, context)) {
      return false;
    }

    if (option.dependsOn.length === 0) {
      return true;
    }

    // If dependent on any answer given on the question
    const questionDependencies = questionDependencyMap.get(option.linkId);
    if (questionDependencies && questionDependencies.size > 0) {
      return answers.some(a => questionDependencies.has(a.linkId) && a.values.some(answerValueHasValue));
    }

    // If dependent on specific answer options
    const optionDependencies = optionDependencyMap.get(option.linkId);
    if (optionDependencies && optionDependencies.length > 0) {
      return optionDependencies.some(optionDependency => answers.some(a => a.linkId === optionDependency.elementLinkId && a.values.some(value => isAnswerOptionValue(optionDependency, value))));
    }

    // Not dependent on any known element or option
    return true;
  }

  function optionIsDependedOn(option: QuestionnaireAnswerOption): boolean {
    return Array.from(optionDependencyMap.values())
      .some(optionDependencies => optionDependencies.some(dependency => dependency.linkId === option.linkId));
  }

  function getVisible(answers: QuestionnaireAnswer[]): QuestionnaireElement[] {
    return elements.filter(element => isVisible(element, answers));
  }
}

interface AnswersCount {
  maxAnswers: number | undefined;
  minAnswers: number | undefined;
}

function getRequiredAnswersCount(question: QuestionnaireElement): AnswersCount {
  switch (question.calculation) {
    case QuestionnaireCalculation.Multiply: return {
      minAnswers: question.operand !== undefined ? 1 : 2,
      maxAnswers: question.operand !== undefined ? 1 : undefined,
    };
    case QuestionnaireCalculation.Difference: return { minAnswers: 2, maxAnswers: 2 };
    default: return { minAnswers: 1, maxAnswers: undefined };
  }
}

function filterAnswerOption(answerOption: QuestionnaireAnswerOption, context: QuestionnaireContext | undefined) {
  const {
    patient,
    program,
    serviceType,
    insurances = [],
    location,
    locationRole,
    serviceLocation,
  } = context ?? {};

  if (patient && !filterByPatient(answerOption, patient)) {
    return false;
  }

  if (answerOption.effective && context?.visitDate && !isDateRangeEffective(answerOption.effective, Approximate.toDate(context.visitDate))) {
    return false;
  }

  if (!anyReferencesEqual(answerOption.programFilters, program ? [program] : [])) {
    return false;
  }

  if (!anyReferencesEqual(answerOption.serviceTypeFilters, serviceType ? [serviceType] : [])) {
    return false;
  }

  if (!anyReferencesEqual(answerOption.insuranceFilters, insurances)) {
    return false;
  }

  if (!anyReferencesEqual(answerOption.locationFilters, location ? [location] : [])) {
    return false;
  }

  if (!anyReferencesEqual(answerOption.serviceLocationFilters, serviceLocation ? [serviceLocation] : [])) {
    return false;
  }

  if (answerOption.locationRoleFilters.length > 0 && (!locationRole || !answerOption.locationRoleFilters.includes(locationRole))) {
    return false;
  }

  return true;
}

function filterQuestionnaireElement(element: QuestionnaireElement, context: QuestionnaireContext): boolean {
  const {
    patient,
    program,
    serviceType,
    insurances = [],
    location,
    locationRole,
    serviceLocation,
    composition,
    narrativeOverrideLinkId,
    hideElementLinkIds,
  } = context ?? {};

  if (patient && !filterByPatient(element, patient)) {
    return false;
  }

  if (element.effective && context?.visitDate && !isDateRangeEffective(element.effective, Approximate.toDate(context.visitDate))) {
    return false;
  }

  if (!anyReferencesEqual(element.programFilters, program ? [program] : [])) {
    return false;
  }

  if (!anyReferencesEqual(element.serviceTypeFilters, serviceType ? [serviceType] : [])) {
    return false;
  }

  if (!anyReferencesEqual(element.insuranceFilters, insurances)) {
    return false;
  }

  if (!anyReferencesEqual(element.locationFilters, location ? [location] : [])) {
    return false;
  }

  if (!anyReferencesEqual(element.serviceLocationFilters, serviceLocation ? [serviceLocation] : [])) {
    return false;
  }

  if (element.locationRoleFilters.length > 0 && (!locationRole || !element.locationRoleFilters.includes(locationRole))) {
    return false;
  }

  if (element.compositionFilters.length > 0 && (!composition || !element.compositionFilters.includes(composition))) {
    return false;
  }

  if (element.linkId === narrativeOverrideLinkId) {
    return false;
  }

  if (hideElementLinkIds && hideElementLinkIds.has(element.linkId)) {
    return false;
  }

  return true;
}

function anyReferencesEqual<T extends ResourceTypes>(filters: Reference<T>[], values: Reference<T>[]) {
  if (filters.length > 0) {
    if (values.length === 0) {
      return false;
    }

    if (!filters.some(filter => values.some(value => resourceEquals(filter, value)))) {
      return false;
    }
  }

  return true;
}

function filterByPatient(source: QuestionnaireElement | QuestionnaireAnswerOption, patient: QuestionnairePatient): boolean {
  const { genderFilter, ageMinimum, ageMaximum } = source;

  if (genderFilter) {
    if (areCodingsSame(genderFilterCodings.male, genderFilter)) {
      if (patient.genderIdentity) { // Prefer identity
        if (!isGenderIdentity(patient.genderIdentity, "male")) {
          return false;
        }
      } else if (patient.gender !== "Male") { // Fallback to gender
        return false;
      }
    } else if (areCodingsSame(genderFilterCodings.female, genderFilter)) {
      if (patient.genderIdentity) { // Prefer identity
        if (!isGenderIdentity(patient.genderIdentity, "female")) {
          return false;
        }
      } else if (patient.gender !== "Female") { // Fallback to gender
        return false;
      }
    }
  }

  const birthDate = LocalDate.toDateTime(patient.birthDate);
  const patientAge = DateTime.now().diff(birthDate, "years").years;

  if (ageMinimum && patientAge < ageMinimum) {
    return false;
  }

  if (ageMaximum && patientAge > ageMaximum) {
    return false;
  }

  return true;
}
