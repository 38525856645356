import styled, { css } from "styled-components";
import { PlaceholderCss } from "~/blots/placeholderBlot";
import { DateCss } from "~/blots/dateBlot";
import { DraftCss } from "~/blots/draftBlot";
import { ChoiceBlotCss } from "~/blots/choiceBlot";
import { UndoCss } from "~/blots/undoBlot";
import { ExpansionCss } from "~/blots/expansionBlot";
import { MentionCss } from "~/blots/mentionBlot";
import { ReadonlyTableCss } from "~/blots/readonlyTableBlot";
import { SuggestionCss } from "~/blots/suggestionBlot";
import { textEmbeds } from "~/blots/register";
import Check from "./check.svg";

export const TypographyCss = css`
  h1 { font-size: var(--font-size-header1); }
  h2 { font-size: var(--font-size-header2); }
  h3 { font-size: var(--font-size-header3); }
  h4 { font-size: var(--font-size-header4); }
  h5 { font-size: var(--font-size-header5); }
  h6 { font-size: var(--font-size-header6); }

  code {
    color: ${props => props.theme.font.default};
    font-size: var(--font-size-small);
    background-color: ${props => props.theme.card.active};
    border-radius: 3px;
    padding: 2px 4px;
  }

  a, a:hover {
    text-decoration: underline;
    color: var(--pt-link-color);
  }
`;

export const ListCss = css`
  li[data-list="bullet"] + li:not([data-list="bullet"]),
  li[data-list="checked"] + li:not([data-list="checked"]):not([data-list="unchecked"]),
  li[data-list="unchecked"] + li:not([data-list="checked"]):not([data-list="unchecked"]),
  li[data-list="ordered"] + li:not([data-list="ordered"]) {
    margin-top: 10px;
  }

  li[data-list="unchecked"],
  li[data-list="checked"] {
    margin: 0.3em 0;
    line-height: 18px;

    > .ql-ui {
      cursor: pointer;

      &:before {
        display: block;
        content: ' ';
        height: 18px;
        width: 18px;
        border: 1px solid var(--gray2); /** Not using theme because we invert() down below based on dark theme */
        border-radius: 3px;
      }
    }
  }

  li[data-list="checked"] {
    text-decoration: line-through;
    color: ${props => props.theme.font.muted};

    > .ql-ui::before {
      background-image: url(${Check});
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  ul[data-checked="true"] > li {
    text-decoration: line-through;
    color: ${props => props.theme.font.muted};
  }

  ul[data-checked] > li {
    margin: 0.3em 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 16px;
  }

  ul[data-checked] > li::before {
    content: ' ';
    border-radius: 3px;
    border: 1px solid var(--gray2);
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }

  ul[data-checked="true"] > li::before {
    content: ' ';
    background-image: url(${Check});
    background-size: 11px;
    background-repeat: no-repeat;
    background-position: center;
    filter: opacity(0.5);
  }

  ul, ol {
    + ul, + ol {
      margin-top: 0;
    }
  }

  .bp5-dark && {
    ul[data-checked] > li::before {
      filter: invert(1);
    }
    li[data-list="unchecked"],
    li[data-list="checked"] {
      > .ql-ui::before {
        filter: invert(1);
      }
    }
  }
`;

const EditableContent = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  > .ql-container,
  > .ql-container > .ql-editor {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  > .ql-container {
    height: auto;
    font-size: var(--font-size-normal);
    font-family: var(--pt-font-family);

    > .ql-editor {
      padding: 0;
      max-height: 100%;
      overflow: unset;
      line-height: calc(20 / 14);

      &:focus {
        outline: none;
      }

      &.ql-blank::before {
        left: 0;
        font-style: normal;
        color: ${props => props.theme.placeholder.default};
      }
    }
  }

  .ql-clipboard {
    /**
      * Fixes the issues where extra line breaks are added when pasting from word
      * see: https://github.com/quilljs/quill/issues/1979#issuecomment-394330508
      */
    white-space: normal;
  }

  h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin: 0;
    &+ h1, &+ h2, &+ h3, &+ h4, &+ h5, &+ h6, &+ p, &+ ul, &+ ol {
      margin-top: 10px;
    }
  }

  ul, ol {
    padding: 0px;
  }

  a, a:hover {
    cursor: text;
  }

  .tab-icon {
    margin-left: 5px;
    padding: 0px 6px;
    display: inline;
    border-radius: 3px;
    line-height: 14px;
    color: ${props => props.theme.font.disabled};
    font-size: var(--font-size-small);
    border: 1px solid ${props => props.theme.border.muted};
    background: rgba(var(--gray3-rgb), 0.05);
  }

  p[data-preview] {
    &:after {
      user-select: none;
      content: attr(data-preview);
      pointer-events: none;
      opacity: 0.5;
    }
  }

  ${textEmbeds.map(blot => css`
    /** HACK: Ensures text selection for embed blots at end of editor can be fully selected.  See BELLS-5785. */
    ${blot.tagName} {
      > span {
        margin-left: 0.5px;
        margin-right: 0.5px;
      }
    }
  `)};

  ${TypographyCss};
  ${ListCss};
  ${ChoiceBlotCss};
  ${DateCss};
  ${DraftCss};
  ${ExpansionCss};
  ${MentionCss};
  ${PlaceholderCss};
  ${UndoCss};
  ${SuggestionCss};
  ${ReadonlyTableCss};

  /* Moves cursors to surround guard characters */
  rh-mention:has(span),
  rh-placeholder:has(span) {
    padding: 0;
  }

  rh-mention,
  rh-placeholder {
    > span {
      margin: 0 3px 1px 3px;
    }
  }
`;

export default EditableContent;
