import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import * as Core from "@blueprintjs/core";
import { uniqueId } from "lodash-es";
import { Placement } from "./popover";
import { useColorScheme } from "./theme";

export interface TooltipProps<TProps extends React.HTMLProps<HTMLElement> = React.HTMLProps<HTMLElement>> extends Omit<React.PropsWithChildren<Core.TooltipProps<TProps>>, "position"> {
  /** @default 400 */
  maxWidth?: number | string | null;
}

export function Tooltip<TProps extends React.HTMLProps<HTMLElement> = React.HTMLProps<HTMLElement>>(props: TooltipProps<TProps>) {
  const {
    children,
    content,
    className,
    disabled: controlledDisabled,
    fill,
    isOpen: controlledIsOpen,
    hoverOpenDelay = 300,
    maxWidth = 400,
    inheritDarkTheme = true,
    targetTagName = "span",
    targetProps,
    openOnTargetFocus = false,
    onInteraction,
    popoverClassName: controlledPopoverClassName,
    placement = "top",
    ...tooltipProps
  } = props;

  const { colorScheme } = useColorScheme();

  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
  const [forceDisabled, setForceDisabled] = useState(false);
  const targetClassName = classnames("tooltip", className, Core.Classes.POPOVER_TARGET, targetProps?.className, {
    [Core.Classes.FILL]: fill,
  });

  const popoverClassName = classnames(controlledPopoverClassName, {
    [Core.Classes.DARK]: inheritDarkTheme && colorScheme.dark,
  });

  const tooltipId = useMemo(() => uniqueId("tooltip-"), []);

  const modifiersCustom = useMemo<Core.PopperCustomModifier[]>(() => [
    {
      name: "aria",
      enabled: true,
      phase: "beforeWrite",
      requires: [],
      fn({ state }) {
        state.elements.popper.style.maxWidth = maxWidth ? typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth : "";
        state.elements.popper.setAttribute("id", tooltipId);
        state.elements.popper.setAttribute("data-random-id", "true");
        state.elements.popper.setAttribute("role", "tooltip");
      },
    },
  ], [tooltipId, maxWidth]);

  const hasNoContent = typeof content === "string" && content.trim().length === 0;
  const disabled = controlledDisabled || forceDisabled || hasNoContent;

  useEffect(() => {
    if (forceDisabled) {
      document.addEventListener("mouseover", handleMouseOver);
      window.addEventListener("focus", handleWindowFocus);
    } else {
      window.addEventListener("blur", handleWindowBlur);
    }

    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
      window.removeEventListener("focus", handleWindowFocus);
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, [forceDisabled]);

  // Target can only be one element, so use fragment to force all given elements to render.
  // This will render a div or span within the target
  const target = Array.isArray(children) ? <>{children}</> : children;

  return (
    <Core.Tooltip
      {...tooltipProps}
      content={content}
      disabled={disabled}
      hoverOpenDelay={hoverOpenDelay}
      inheritDarkTheme={inheritDarkTheme}
      isOpen={controlledIsOpen ?? uncontrolledIsOpen}
      modifiers={{
        ...tooltipProps.modifiers,
        arrow: {
          ...tooltipProps.modifiers?.arrow,
          options: {
            padding: 6,
            ...tooltipProps.modifiers?.arrow?.options,
          },
        },
        flip: {
          ...tooltipProps.modifiers?.flip,
          options: {
            fallbackPlacements: [oppositePlacement(placement), "auto"],
          },
        },
        preventOverflow: {
          ...tooltipProps.modifiers?.preventOverflow,
          options: {
            padding: 15,
            ...tooltipProps.modifiers?.preventOverflow?.options,
          },
        },
      }}
      modifiersCustom={modifiersCustom}
      openOnTargetFocus={openOnTargetFocus}
      placement={placement}
      popoverClassName={popoverClassName}
      targetProps={{
        "className": targetClassName,
        "aria-describedby": tooltipId,
        ...targetProps as TProps,
      }}
      targetTagName={targetTagName}
      onInteraction={handleInteraction}
    >
      {target}
    </Core.Tooltip>
  );

  function handleInteraction(nextOpenState: boolean, e?: React.SyntheticEvent<HTMLElement>) {
    // If disabled, ignore attempt to open.  This helps if disabled was set to true during the hover open delay timer.
    if (disabled && uncontrolledIsOpen) {
      setUncontrolledIsOpen(false);
      onInteraction?.(false, e);
    } else {
      setUncontrolledIsOpen(nextOpenState);
      onInteraction?.(nextOpenState, e);
    }
  }

  function handleMouseOver() {
    setForceDisabled(false);
  }

  function handleWindowFocus() {
    setForceDisabled(false);
  }

  function handleWindowBlur() {
    setForceDisabled(true);
  }
}

function oppositePlacement(placement: Placement): Placement {
  switch (placement) {
    case "auto": return "auto";
    case "auto-end": return "auto-start";
    case "auto-start": return "auto-end";
    case "bottom": return "top";
    case "bottom-start": return "top-start";
    case "bottom-end": return "top-end";
    case "top": return "bottom";
    case "top-start": return "bottom-start";
    case "top-end": return "bottom-end";
    case "left": return "left";
    case "left-start": return "left-start";
    case "left-end": return "left-end";
    case "right": return "right";
    case "right-start": return "right-start";
    case "right-end": return "right-end";
  }
}
