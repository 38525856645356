import React, { useEffect, useState } from "react";
import { find, sortBy } from "lodash-es";
import { Confirm } from "@remhealth/icons";
import {
  Coding,
  HealthcareService,
  HealthcareServiceSortField,
  LocalDate,
  LocationRole,
  NoteDefinition,
  NoteDefinitionFilterSet,
  SortDirection,
  SortField,
  knownCodings
} from "@remhealth/apollo";
import { resourceEquals, useApollo, useProductFlag } from "@remhealth/host";
import {
  ShowMoreText,
  useFeed,
  useHealthcareServicesView,
  useLabeling,
  useLoadables,
  useStore
} from "@remhealth/core";
import {
  ColumnFilter,
  ColumnSuggestFilter,
  Ellipsize,
  GridFeed,
  Icon,
  IconSize,
  InteractiveRow,
  InteractiveRowState,
  NonIdealIcon,
  PagingResult,
  SortButton,
  Spinner,
  useAbort
} from "@remhealth/ui";

import { Text } from "~/text";
import { doesServiceTypeRequireUnits, getAllPlaceOfServices, getLocationRole, isServiceTypeBillable, serviceTypeHasSupportsGroup } from "~/utils";

import { useAllowGroups } from "~/contexts";
import {
  ActionHeader,
  BillableCell,
  BillableHeader,
  GroupContainer,
  LongTextCell,
  SectionTypeCell,
  UnitsContainer
} from "./serviceTypeGrid.styles";

type BillableStatus = "Billable" | "Non-Billable";
const BillableStatuses: BillableStatus[] = ["Billable", "Non-Billable"];
const UnitStatuses: string[] = ["Units", "Time"];
const GroupStatuses: string[] = ["Group", "Non-Group"];

export type ServiceTypeGridColumn = "Service" | "ServiceTypeCode" | "NonBillable" | "UseUnits" | "NoteType" | "PlaceOfService" | "OverlapAllowed" | "Group";

export interface ServiceTypeGridProps {
  pageKey: string;
  columns: ServiceTypeGridColumn[];
  query?: string;
  notConfiguredServiceTypesOnly?: boolean;
  isFetchingNotConfiguredServiceTypes?: boolean;
  effectiveDate?: LocalDate;
  allowedLocations?: LocationRole[];
  limitToServiceTypeIds?: string[];
  excludedServiceTypeIds?: string[];
  showOverlappingServiceExceptions?: boolean;
  missedVisitsOnly?: boolean;
  actionHeader?: JSX.Element;
  actionCell?: (row: HealthcareService, rowProps: InteractiveRowState) => JSX.Element;
  onClick?: (service: HealthcareService) => void;
}

export const ServiceTypeGrid = (props: ServiceTypeGridProps) => {
  const {
    columns,
    pageKey,
    query,
    effectiveDate,
    allowedLocations = [],
    notConfiguredServiceTypesOnly,
    isFetchingNotConfiguredServiceTypes,
    missedVisitsOnly = false,
    limitToServiceTypeIds,
    excludedServiceTypeIds,
    showOverlappingServiceExceptions,
    actionHeader,
    actionCell,
    onClick,
  } = props;

  const apollo = useApollo();
  const store = useStore();
  const abort = useAbort();
  const labels = useLabeling();
  const allowGroups = useAllowGroups();
  const showNonBillableAndUnits = useProductFlag("ShowNonBillableandUnits");
  const showLocationRole = useProductFlag("ShowLocationRole");

  const [noteDefinitionFilteredItems, setNoteDefinitionFilteredItems] = useState<NoteDefinition[]>([]);
  const [billableFilteredItems, setBillableFilteredItems] = useState<BillableStatus[]>([]);
  const [unitStatusFilteredItems, setUnitStatusFilteredItems] = useState<string[]>([]);
  const [filteredPlacesOfService, setFilteredPlacesOfService] = useState<Coding[]>([]);
  const [groupStatusFilteredItems, setGroupStatusFilteredItems] = useState<string[]>([]);

  const [sortPredicate, setSortPredicate] = useState<SortField<HealthcareServiceSortField>>({
    field: HealthcareServiceSortField.Display,
    direction: SortDirection.Ascending,
  });

  useEffect(() => {
    if (!notConfiguredServiceTypesOnly) {
      setNoteDefinitionFilteredItems([]);
    }
  }, [notConfiguredServiceTypesOnly]);

  let billable: boolean | undefined;
  let requireUnits: boolean | undefined;
  let group: boolean | undefined;

  if (groupStatusFilteredItems.length === 1) {
    if (groupStatusFilteredItems.includes("Group")) {
      group = true;
    } else if (groupStatusFilteredItems.includes("Non-Group")) {
      group = false;
    }
  }

  if (billableFilteredItems.length === 1) {
    if (billableFilteredItems.includes("Billable")) {
      billable = true;
    } else if (billableFilteredItems.includes("Non-Billable")) {
      billable = false;
    }
  }

  if (unitStatusFilteredItems.length <= 1 && unitStatusFilteredItems.includes("Time")) {
    requireUnits = false;
  } else if (unitStatusFilteredItems.length <= 1 && unitStatusFilteredItems.includes("Units")) {
    requireUnits = true;
  }

  const limitedToIds = noteDefinitionFilteredItems.length > 0 ? noteDefinitionFilteredItems.flatMap(n => n.services.map(s => s.id)) : undefined;
  const filteredRoles = filteredPlacesOfService.length > 0 ? filteredPlacesOfService.map(l => getLocationRole(l)) : [];

  const view = useHealthcareServicesView(sortPredicate, {
    query,
    billable,
    requireUnits,
    effectiveDate,
    group,
    missedVisitsOnly,
    showOverlappingServiceExceptions,
    allowedLocations: intersect(allowedLocations, filteredRoles),
    ids: intersect(limitToServiceTypeIds, limitedToIds),
    notIds: excludedServiceTypeIds && excludedServiceTypeIds.length > 0 ? excludedServiceTypeIds : undefined,
  });

  const feed = useFeed(view);

  const associatedNoteTypes = useLoadables(feed.items.map(i => i.id), mapAssociatedNoteTypes);

  useEffect(() => store.noteDefinitions.onUpserted(associatedNoteTypes.unloadAll), []);

  const numberOfColumns = columns.length + (actionCell || actionHeader ? 2 : 1);

  return (
    <GridFeed<HealthcareService>
      allowWrap
      stickyHeader
      feed={feed}
      headerRenderer={headerRenderer}
      interactive={!!onClick}
      noResults={renderEmptyRecord}
      pageKey={pageKey}
      rowRenderer={rowRenderer}
    />
  );

  function renderEmptyRecord() {
    const description = query
      ? Text.NoSearchResults(labels.ServiceType)
      : `There are no ${labels.serviceTypes} to display.`;

    return (
      <tr>
        <td colSpan={numberOfColumns}>
          <NonIdealIcon
            description={description}
            icon="search"
            intent={query ? "danger" : "primary"}
            title={Text.NoResultTitle(labels.serviceTypes)}
          />
        </td>
      </tr>
    );
  }

  function headerRenderer() {
    return (
      <tr>
        {columns.map(column => (
          <React.Fragment key={column}>{renderHeader(column)}</React.Fragment>
        ))}
        {(actionHeader || actionCell) && <ActionHeader>{actionHeader}</ActionHeader>}
      </tr>
    );
  }

  function renderHeader(column: ServiceTypeGridColumn) {
    const { field, direction } = sortPredicate;
    switch (column) {
      case "Service":
        return (
          <th>
            <SortButton
              label={labels.ServiceType}
              sort={field === HealthcareServiceSortField.Display ? direction : "Unspecified"}
              onClick={handleSortByServiceType}
            />
          </th>
        );
      case "ServiceTypeCode":
        return (
          <th>
            <SortButton
              label={labels.ServiceTypeCode}
              sort={field === HealthcareServiceSortField.Alias ? direction : "Unspecified"}
              onClick={handleSortByCode}
            />
          </th>
        );
      case "NonBillable":
        return (
          showNonBillableAndUnits && (
            <BillableHeader>
              Non-Billable
              <ColumnFilter<BillableStatus>
                aria-label="Filter by Non-Billable"
                items={BillableStatuses}
                labelRenderer={item => item}
                selectedItems={billableFilteredItems}
                onChange={setBillableFilteredItems}
              />
            </BillableHeader>
          )
        );
      case "UseUnits":
        return (
          showNonBillableAndUnits && (
            <th>
              {Text.UseUnits}
              <ColumnFilter<string>
                aria-label={`Filter by ${Text.UseUnits}`}
                items={UnitStatuses}
                labelRenderer={s => s}
                selectedItems={unitStatusFilteredItems}
                onChange={setUnitStatusFilteredItems}
              />
            </th>
          )
        );
      case "NoteType":
        return (
          <th>
            Note Type(s){" "}
            <ColumnSuggestFilter<NoteDefinition>
              aria-label="Filter by Note Types"
              disabled={notConfiguredServiceTypesOnly || isFetchingNotConfiguredServiceTypes}
              itemsEqual={resourceEquals}
              noSelectionsContent="No Note Types selected"
              optionRenderer={i => i.name}
              queryable={noteDefinitionQueryable}
              onSelectedItemsChange={setNoteDefinitionFilteredItems}
            />
          </th>
        );
      case "PlaceOfService":
        return (
          showLocationRole && (
            <th>
              {Text.PlaceOfService}(s){" "}
              <ColumnSuggestFilter<Coding>
                aria-label={`Filter by ${Text.PlaceOfService}`}
                itemsEqual={codingEquals}
                noSelectionsContent={<>No {Text.PlaceOfService} selected</>}
                optionRenderer={placeOfServiceRenderer}
                queryable={placeOfServiceQueryable}
                onSelectedItemsChange={handleSelectedPlaceOfServiceChange}
              />
            </th>
          )
        );
      case "OverlapAllowed":
        return (
          <th>Overlap Allowed</th>
        );
      case "Group":
        return (
          allowGroups && (
            <th>
              {Text.Group}
              <ColumnFilter<string>
                aria-label={`Filter by ${Text.Group}`}
                items={GroupStatuses}
                labelRenderer={s => s}
                selectedItems={groupStatusFilteredItems}
                onChange={setGroupStatusFilteredItems}
              />
            </th>
          )
        );
    }
  }

  async function noteDefinitionQueryable(query: string, abort: AbortSignal): Promise<PagingResult<NoteDefinition>> {
    const page = await apollo.noteDefinitions.query({
      filters: [{ display: { startsWithAllWords: query.trim() }, status: { matches: "Active" }, type: { matches: "Note" } }],
      abort,
    });
    return { items: page.results, hasMore: !!page.continuationToken };
  }

  function getFormattedLocationRole(locationRole: LocationRole) {
    const placeOfService = find(knownCodings.placeOfServices, (_, key) => key.toLowerCase() === locationRole.toLowerCase());
    if (placeOfService) {
      return placeOfService.code + " - " + placeOfService.display;
    }
    return "";
  }

  function rowRenderer(healthcareService: HealthcareService) {
    return (
      <InteractiveRow key={healthcareService.id} onClick={onClick ? () => onClick(healthcareService) : undefined}>
        {rowProps => (
          <>
            {columns.map(column => (
              <React.Fragment key={column}>{renderCell(healthcareService, column)}</React.Fragment>
            ))}
            {(actionHeader || actionCell) && (
              <td onClick={e => e.stopPropagation()}>
                {actionCell?.(healthcareService, rowProps)}
              </td>
            )}
          </>
        )}
      </InteractiveRow>
    );
  }

  function renderCell(healthcareService: HealthcareService, column: ServiceTypeGridColumn) {
    const locationRoles = sortBy(healthcareService.allowedLocations.map(l => getFormattedLocationRole(l))).join(", ");
    switch (column) {
      case "Service":
        return (
          <SectionTypeCell>
            <Ellipsize>{healthcareService.display}</Ellipsize>
          </SectionTypeCell>
        );
      case "ServiceTypeCode":
        return <td>{healthcareService.aliases[0]}</td>;
      case "NonBillable":
        return showNonBillableAndUnits && (
          <BillableCell>
            {!isServiceTypeBillable(healthcareService) && <Icon icon={<Confirm />} intent="primary" size={IconSize.LARGE} />}
          </BillableCell>
        );
      case "UseUnits":
        return showNonBillableAndUnits && (
          <td>
            <UnitsContainer>
              {doesServiceTypeRequireUnits(healthcareService) && <Icon icon={<Confirm />} intent="primary" size={IconSize.LARGE} />}
            </UnitsContainer>
          </td>
        );
      case "NoteType":
        return (
          <td>
            {associatedNoteTypes.status(healthcareService.id) === "loading"
              ? <Spinner intent="primary" size={20} />
              : (
                <LongTextCell>
                  <ShowMoreText lines={2}>
                    {getNoteTypeNameList(associatedNoteTypes.get(healthcareService.id) ?? [])}
                  </ShowMoreText>
                </LongTextCell>
              )}
          </td>
        );
      case "PlaceOfService":
        return showLocationRole && (
          <td>
            <LongTextCell>
              <ShowMoreText lines={2}>{locationRoles}</ShowMoreText>
            </LongTextCell>
          </td>
        );
      case "OverlapAllowed":
        return (
          <td>
            <LongTextCell>
              <ShowMoreText lines={2}>
                {healthcareService.overlappingServiceExceptions.map(s => s.display).join(", ")}
              </ShowMoreText>
            </LongTextCell>
          </td>
        );
      case "Group":
        return allowGroups && (
          <td>
            <GroupContainer>
              {serviceTypeHasSupportsGroup(healthcareService) && <Icon icon={<Confirm />} intent="primary" size={IconSize.LARGE} />}
            </GroupContainer>
          </td>
        );
    }
  }

  function getNoteTypeNameList(noteTypesList: NoteDefinition[]) {
    return noteTypesList.map(s => s.name).join(", ");
  }

  function handleSortByServiceType() {
    handleSortBy(HealthcareServiceSortField.Display);
  }

  function handleSortByCode() {
    handleSortBy(HealthcareServiceSortField.Alias);
  }

  function handleSortBy(field: HealthcareServiceSortField) {
    let direction: SortDirection = SortDirection.Ascending;
    if (sortPredicate.field === field) {
      direction = getReverseSortDirection(sortPredicate.direction);
    }

    setSortPredicate({ field, direction });
  }

  function getReverseSortDirection(sortDirection?: SortDirection): SortDirection {
    return sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
  }

  async function mapAssociatedNoteTypes(serviceTypeIds: string[]) {
    const noteTypes: NoteDefinition[] = await fetchNoteTypes([{
      service: { in: serviceTypeIds },
      status: { matches: "Active" },
      type: { matches: "Note" },
    }]);

    const map = new Map<string, NoteDefinition[]>();

    serviceTypeIds.forEach(serviceTypeId => {
      const associatedNoteTypes = noteTypes.filter(n => n.services.some(s => s.id === serviceTypeId));
      map.set(serviceTypeId, sortBy(associatedNoteTypes, "display"));
    });

    return map;
  }

  async function fetchNoteTypes(filters?: NoteDefinitionFilterSet[]) {
    const noteDefinitionFeed = apollo.noteDefinitions.feed({
      filters,
    });
    return await noteDefinitionFeed.all({ abort: abort.signal });
  }

  function placeOfServiceRenderer(location: Coding) {
    return `${location.code ?? ""} - ${location.display ?? ""}`;
  }

  function codingEquals(item1: Coding, item2: Coding) {
    return item1.code === item2.code && item1.system === item2.system;
  }

  function handleSelectedPlaceOfServiceChange(selectedPlacesOfService: Coding[]) {
    setFilteredPlacesOfService(selectedPlacesOfService);
  }

  async function placeOfServiceQueryable(query: string): Promise<PagingResult<Coding>> {
    const items = getAllPlaceOfServices().filter(p => {
      const nameMatched = p.display?.split(" ").some(w => w.toLowerCase()
        .startsWith(query.toLowerCase()));
      const codeMatched = p.code.toLowerCase()
        .startsWith(query.toLowerCase());
      return nameMatched || codeMatched;
    });
    return new Promise((resolve) => resolve({ items, hasMore: false }));
  }
};

function intersect<T>(left: T[] | undefined, right: T[] | undefined): T[] | undefined {
  if (left === undefined) {
    if (right === undefined) {
      return undefined;
    }

    return left;
  }

  if (right === undefined) {
    return left;
  }

  return left.length > 0 && right.length > 0
    ? left.filter(value => right.includes(value))
    : left.length > 0
      ? left
      : right.length > 0 ? right : [];
}
