import { PropsWithChildren, useState } from "react";
import { StyleSheetManager, ThemeProvider, createGlobalStyle } from "styled-components";
import { ColorSchemeContext, ColorSchemeName, FontAppearance, getTheme, useMediaQuery } from "@remhealth/ui";
import { ThemeMounter } from "./themeMounter";
import { BellsThemeName, ThemeContext } from "./useTheme";

interface GlobalStyleProps {
  $zoom: number;
}

const LargeFontStyles = createGlobalStyle<GlobalStyleProps>`
  body {
    zoom: var(--zoom);
  }

  :root {
    --zoom: ${props => props.$zoom};
  }
`;

export const Theme = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  const [colorScheme, setColorScheme] = useState<ColorSchemeName>("light");
  const [theme, setTheme] = useState<BellsThemeName>("default");
  const [fontAppearance, setFontAppearance] = useState<FontAppearance>("Normal");
  const [overridden, setOverridden] = useState(false);

  const gridSize = 10;
  const colorTheme = getTheme(colorScheme, gridSize);

  const isSmallScreen = useMediaQuery("screen and (max-device-width: 1600px)");
  const isTinyScreen = useMediaQuery("screen and (max-device-width: 1179px)");

  const zoom = getZoom();

  return (
    <>
      <LargeFontStyles $zoom={zoom} />
      <StyleSheetManager namespace=".bells-app">
        <>
          <ThemeProvider theme={colorTheme}>
            <ThemeMounter fontAppearance={fontAppearance} theme={overridden ? "default" : theme}>
              <ThemeContext.Provider value={{ theme, setTheme }}>
                <ColorSchemeContext.Provider
                  value={{
                    overridden,
                    colorScheme: colorTheme,
                    gridSize,
                    zoom,
                    fontAppearance,
                    setColorScheme,
                    setFontAppearance,
                    setOverridden,
                  }}
                >
                  {children}
                </ColorSchemeContext.Provider>
              </ThemeContext.Provider>
            </ThemeMounter>
          </ThemeProvider>
        </>
      </StyleSheetManager>
    </>
  );

  function getZoom(): number {
    const isLargeFont = fontAppearance === "Large";

    if (isTinyScreen) {
      return isLargeFont ? 1 : 0.8;
    }

    // Rescale entire site if user is on tablet or small monitor
    // Note: This also handles case where user has too high of DPI set for their small resolution monitors
    if (isSmallScreen) {
      return isLargeFont ? 1.125 : 0.875;
    }

    return isLargeFont ? 1.25 : 1;
  }
};
