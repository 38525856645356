import { createContext, useContext } from "react";
import type { FormValues } from "./types";
import type { FormContent } from "./form";

export interface FormContext<T extends FormValues> {
  readonly form: FormContent<T>;
}

export const FormContext = createContext<FormContext<any>>({
  get form(): FormContent<any> {
    throw new Error("FormContext is not initialized");
  },
});

export function useFormContent<T extends FormValues>(): FormContent<T> {
  return useContext<FormContext<any>>(FormContext).form;
};
