import React from "react";
import { TooltipProps } from "@blueprintjs/core";
import { Tooltip } from "./tooltip";
import { BlockContent, InlineContent, MultilineContent, TooltipContent } from "./ellipsize.styles";

export interface EllipsizeProps extends Omit<TooltipProps, "content" | "targetTagName" | "wrapperTagName"> {
  /**
   * Styles the ellipsize container with a block styled div instead of span inline element.
   * @default false
   */
  block?: boolean;

  /**
   * Max width of the content shown inside the tooltip.  This is NOT a max width of the content itself.
   * @default 400
   */
  maxWidth?: number | null;

  /**
   * Number of lines to show before ellipsizing the text.
   * @default 1
   */
  lines?: number;

  /**
   * Custom content to show in the tooltip.  Defaults to the same as children.
   */
  tooltipContent?: React.ReactNode;
}

export const Ellipsize = (props: EllipsizeProps & Readonly<{ children?: React.ReactNode }>) => {
  const {
    children,
    className,
    placement = "top",
    interactionKind = "hover-target",
    block = false,
    maxWidth = 400,
    tooltipContent,
    ...tooltipProps
  } = props;

  if (!children) {
    return null;
  }

  function renderContent() {
    const { lines = 1 } = props;

    if (lines > 1) {
      return (
        <MultilineContent $lines={lines} className={className}>
          {children}
        </MultilineContent>
      );
    }

    if (block) {
      return (
        <BlockContent className={className}>{children}</BlockContent>
      );
    }

    return (
      <InlineContent className={className}>{children}</InlineContent>
    );
  }

  return (
    <Tooltip
      className={block ? "ellipsize-target-block" : "ellipsize-target-inline"}
      content={<TooltipContent $maxWidth={maxWidth}>{tooltipContent ?? children}</TooltipContent>}
      disabled={tooltipContent !== undefined && !tooltipContent}
      interactionKind={interactionKind}
      maxWidth={maxWidth}
      placement={placement}
      targetTagName={block ? "div" : "span"}
      {...tooltipProps}
    >
      {renderContent()}
    </Tooltip>
  );
};
