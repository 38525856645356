import type { Node, Parent } from "unist";
import type { Processor, Transformer } from "unified";
import { visit } from "unist-util-visit";
import { type ElementNode, type TextNode, isElementNode, isTextNode } from "./utils";

const guardText = "\uFEFF" as const;

interface GuardNode extends Node {
  type: "text";
  value?: typeof guardText;
}

/**
 * Removes undesired content from html, particularly junk that comes from the quill editor.
 */
export function sanitize(this: Processor): Transformer {
  return function transformer(tree: Node | ElementNode | TextNode): Node {
    // First pass, normalize tree structure
    visit(tree, (node, index, parent) => {
      if (isElementNode(node)) {
        // Embed blots contain text guards that should be removed
        if (parent && index !== undefined) {
          if (node.children?.length === 3) {
            const [first, second, third] = node.children;
            if (isGuardNode(first) && isGuardNode(third)
              && isElementNode(second) && second.tagName.toLowerCase() === "span") {
              parent.children[index] = {
                ...second,
                tagName: node.tagName,
                properties: node.properties,
              };
            }
          }

          // Remove undo and draft spans
          if (node.tagName === "rh-undo" || node.tagName === "rh-draft") {
            parent.children.splice(index, 1, ...node.children ?? []);
          }
        }

        // Normalize lists and tasklists
        if (node.tagName === "ol" && index !== undefined && parent) {
          normalizeList(node, index, parent);
        }
      }
    }, true);

    // Second pass, sanitize elements
    visit(tree, (node, _index, _parent) => {
      if (isElementNode(node)) {
        if (node.properties) {
          delete node.properties.contentEditable;
          delete node.properties.contenteditable;
          delete node.properties.dataPreview;
        }
      } else if (isTextNode(node)) {
        if (node.value) {
          node.value = replaceAll(node.value, "\uFEFF", ""); // Remove GuardText
          node.value = replaceAll(node.value, "\u00A0", " "); // Replace nbsp with space
        }
      }
    }, true);

    return tree;
  };
}

/**
 * Removes undesired content from html that isn't suitable for react components
 */
export function reactSanitize(this: Processor): Transformer {
  return function transformer(tree: Node | ElementNode): Node {
    visit(tree, isElementNode, node => {
      if (node.properties?.ref) {
        node.properties.dataRef = node.properties.ref;
        delete node.properties.ref;
      }
    });

    return tree;
  };
}

function isGuardNode(node: any): node is GuardNode {
  return node && node.type === "text" && node.value === guardText;
}

function replaceAll(input: string, search: string, replacement: string) {
  return input.replace(new RegExp(search, "g"), replacement);
}

function normalizeList(list: ElementNode, listIndex: number, parent: Parent) {
  if (list.children.length === 0) {
    return;
  }

  let targetList = list;
  for (let liIndex = list.children.length - 1; liIndex >= 0; liIndex--) {
    const li = list.children[liIndex];
    if (isLi(li)) {
      targetList = normalizeListItem(li, liIndex, targetList, list, listIndex, parent);
    }
  }

  if (list.children.length === 0) {
    parent.children.splice(listIndex, 1);
  }
}

function normalizeListItem(li: ElementNode, liIndex: number, targetList: ElementNode, originalList: ElementNode, listIndex: number, listParent: Parent) {
  const type = li.properties.dataList;

  if (typeof type === "string") {
    if (!isListType(type, targetList)) {
      // Create new list
      targetList = { ...originalList, properties: { ...originalList.properties }, children: [li] };
      makeListType(type, targetList);
      listParent.children.splice(listIndex + 1, 0, targetList);
    } else if (targetList !== originalList) {
      targetList.children.unshift(li);
    }

    delete li.properties.dataList;
  }

  li.children = li.children.filter(c => !isQlUi(c));

  // Remove from old list
  if (targetList !== originalList) {
    originalList.children.splice(liIndex, 1);
  }

  return targetList;
}

function isLi(node: Node): node is ElementNode {
  return isElementNode(node) && node.tagName.toLowerCase() === "li";
}

function isQlUi(node: Node): boolean {
  return isElementNode(node) && Array.isArray(node.properties.className) && node.properties.className.includes("ql-ui");
}

function isListType(type: string, list: ElementNode): boolean {
  switch (type) {
    default:
    case "ordered": return list.tagName === "ol" && list.properties.dataChecked === undefined;
    case "bullet": return list.tagName === "ul" && list.properties.dataChecked === undefined;
    case "checked": return list.tagName === "ul" && list.properties.dataChecked === "true";
    case "unchecked": return list.tagName === "ul" && list.properties.dataChecked === "false";
  }
}

function makeListType(type: string, list: ElementNode) {
  switch (type) {
    case "ordered":
      list.tagName = "ol";
      delete list.properties.dataChecked;
      break;
    case "bullet":
      list.tagName = "ul";
      delete list.properties.dataChecked;
      break;
    case "checked":
      list.tagName = "ul";
      list.properties.dataChecked = "true";
      break;
    case "unchecked":
      list.tagName = "ul";
      list.properties.dataChecked = "false";
      break;
  }
}
